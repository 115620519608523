const EXCLUDED_TAGS = ['script'];
const FOCUS_OPTIONS = {
  child: 'child',
  itself: 'itself',
};

export const enableFocusTrapping = (rootElement) => {
  const childrenElements = Array.from(rootElement.children);
  childrenElements.forEach((element) => {
    if (!EXCLUDED_TAGS.includes(element.tagName.toLowerCase())) {
      switch (element.getAttribute('data-focus-trapping')) {
        case FOCUS_OPTIONS.child:
          enableFocusTrapping(element);
          break;
        case FOCUS_OPTIONS.itself:
          break;
        default:
          element.setAttribute('data-hidden-focus', 'true');
          element.setAttribute('aria-hidden', 'true');
          break;
      }
    }
  });
};

export const disableFocusTrapping = () => {
  const items = document.querySelectorAll('[data-hidden-focus="true"]');
  items.forEach((item) => {
    item.removeAttribute('data-hidden-focus');
    item.removeAttribute('aria-hidden');
  });
};
