import event from '../../event/js/module';

const addFormFieldDisabledValidation = (fieldEvents, fieldTargetClassToDisable) => {
  fieldEvents.forEach((fieldEvent) => {
    event.eventHandler(
      `${fieldEvent}-validate`,
      fieldEvent,
      (dataElement, targetElement) => {
        dataElement.classList.add('touched');
        document.querySelectorAll(fieldTargetClassToDisable).forEach((fieldTarget) => {
          // eslint-disable-next-line no-param-reassign
          fieldTarget.disabled = !targetElement.checkValidity();
        });
      },
    );
  });
};

const addValidationErrorInputStyles = () => {
  const fields = document.querySelectorAll('.umbraco-forms-field');

  fields.forEach((field) => {
    const fieldSpan = field.querySelector('span');

    if (fieldSpan.className === 'field-validation-error') {
      const inputField = field.querySelector('input');
      const textareaField = field.querySelector('textarea');

      if (inputField) {
        inputField.classList.add('input-validation-error');

        inputField.onfocusout = () => {
          fieldSpan.setAttribute('class', 'field-validation-valid');
          fieldSpan.innerHTML = '';
        };
      }

      if (textareaField) {
        textareaField.classList.add('input-validation-error');

        textareaField.onfocusout = () => {
          fieldSpan.setAttribute('class', 'field-validation-valid');
          fieldSpan.innerHTML = '';
        };
      }
    }
  });
};

const hideFormValidationSummary = () => {
  const form = document.querySelector('.umbraco-forms-form');
  const validationSummary = form.querySelector('.validation-summary-errors');
  if (validationSummary) {
    validationSummary.style.display = 'none';
  }
};

export
{
  addFormFieldDisabledValidation,
  addValidationErrorInputStyles,
  hideFormValidationSummary,
};
