const initializeCheckboxCards = () => {
  document.querySelectorAll('.at-checkbox-card input.at-check__input')
    .forEach((checkboxCard) => checkboxCard.addEventListener('click', (ev) => {
      if (ev.target.checked) {
        ev.target.closest('.at-checkbox-card').classList.add('at-checkbox-card--selected');
      } else {
        ev.target.closest('.at-checkbox-card').classList.remove('at-checkbox-card--selected');
      }
    }));
};

export default initializeCheckboxCards;
