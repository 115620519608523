/* eslint-disable import/extensions */
import { CardSelector } from '../../card-selector/js/module.js';
import CCCGoogleAnalytics4 from './CCCGoogleAnalytics4.js';

/*
    This is the CCC entry store
    - If making changes in the actual CCC store, might need to update the CCC layout store as well for screenshot testing purposes.
*/

export default (ga4MeasurementId) => ({
  state: {
    selectedOption: null,
  },
  optionValue: {
    yes: 'yes',
    no: 'no',
  },
  optionIcon: {
    yes: {
      enablePreregistrationFlow: 'badge-account-tick',
      disablePreregistrationFlow: 'badge-athop-registered',
    },
    no: {
      enablePreregistrationFlow: 'badge-account-noaccount',
      disablePreregistrationFlow: 'badge-athop-delivery',
    },
  },
  optionText: {
    title: {
      yes: {
        enablePreregistrationFlow: 'Yes, I have an account.',
        disablePreregistrationFlow: 'Yes, I have an AT HOP card.',
      },
      no: {
        enablePreregistrationFlow: 'No, I don\'t have an account.',
        disablePreregistrationFlow: 'No, I don\'t have an AT HOP card.',
      },
    },
    description: {
      yes: {
        enablePreregistrationFlow: 'Log in to apply the Community Connect concession on an existing AT HOP card, or apply for a new discount loaded HOP card.',
        disablePreregistrationFlow: 'Log in to apply the Community Connect concession on an existing card.',
      },
      no: {
        enablePreregistrationFlow: 'Apply for a new AT HOP card. The Community Connect discount is pre-loaded to this card.',
        disablePreregistrationFlow: 'Apply for a new card. The Community Connect discount will be pre-loaded to this card.',
      },
    },
  },
  showAlert: false,
  alertTitle: null,
  alertDescription: 'Select an option before continue to application',
  accessibilityErrorFocus: false, // accessibility focus toggler after error alert is clicked/pressed

  init() {
    if (!ga4MeasurementId) throw new Error('GA4 Measurement Id is mandatory');
    this.ga4 = new CCCGoogleAnalytics4(ga4MeasurementId);
    const entryCardSelector = document.getElementById('SelectedCardTypeSelection');
    if (entryCardSelector) this.state.selectedOption = new CardSelector(entryCardSelector);
  },

  hideSimpleAlert() {
    this.showAlert = false;
  },

  showErrorField() {
    this.accessibilityErrorFocus = true;
    this.setFocus('refMyATAccountSelection');
  },

  setFocus(refElement) {
    this.$nextTick(() => {
      this.$refs[refElement].focus();
    });
  },

  handleContinue() {
    document.querySelectorAll('.validation-summary').forEach((el) => el.remove());
    const validOptions = [this.optionValue.yes, this.optionValue.no];

    if (!validOptions.includes(this.state.selectedOption)) {
      this.showAlert = true;
      this.$nextTick(() => {
        this.$refs.refSimpleAlert.focus();
      });
    }

    if (this.state.selectedOption === this.optionValue.yes) {
      this.ga4.event('select_has_account', { has_account: 'yes' });
      window.location.href = '/authenticated-ccc';
    }

    if (this.state.selectedOption === this.optionValue.no) {
      this.ga4.event('select_has_account', { has_account: 'no' });
      window.location.href = '/anonymous-ccc?source=entry_page';
    }

    return false;
  },
});
