/* eslint-disable import/extensions */
import 'focus-visible/dist/focus-visible.min.js';

/**
 * Card Selector
 * An accessible component that allows a user to select a card (button) from two or more options
 * Consists of a hidden input and a list of cards
 *
 * How to implement:
 * - It needs certain elements with attributes and classes for the styling, please look at the EJS template as a guide
 *
 * - For the JS part, basically it needs to create an instance of CardSelector class and assign it to the target input element
 *   There are 2 options:
 *    1. Use initializeCardSelector()
 *       This is a global implementation
 *       It assigns an instance of CardSelector class to every input element that has a `data-element="card-selector"` attribute
 *       Example: look at the EJS template
 *
 *    2. Explicitly create and assign an instance of CardSelector class for each target input element
 *       This is a more specific implementation
 *       Example: look at `init card type selector` in store_authenticated_with_card.js in ccc-core
 *       In at_website there is a CardSelectorOption.cshtml partial view for the CCC template
 *
 * Event listeners:
 * - Input element: onInput
*/

const keyCode = {
  SPACE: 32,
  RETURN: 13,
  TAB: 9,
};

const updateButtonStates = (value, buttonElements) => {
  buttonElements.forEach((buttonElement) => {
    if (buttonElement.dataset.itemValue === value) {
      buttonElement.classList.add('at-card-selector__item--selected');
      buttonElement.setAttribute('aria-pressed', true);
    } else {
      buttonElement.classList.remove('at-card-selector__item--selected');
      buttonElement.setAttribute('aria-pressed', false);
    }
  });
};

const handleSelection = ({ inputElement, buttonElement, userEvent }) => {
  userEvent.preventDefault();

  const cardSelectorValue = inputElement.getAttribute('value');

  if (cardSelectorValue !== buttonElement.dataset.itemValue && buttonElement.dataset.state === 'active') {
    inputElement.setAttribute('value', buttonElement.dataset.itemValue);
  }
};

class CardSelector {
  constructor(inputElement) {
    this.inputElement = inputElement;
    this.buttonElements = document.querySelectorAll(`[data-card-selector-button-for=${inputElement.id}]`);

    // Use MutationObserver to detect a value change
    const observer = new MutationObserver((mutationRecords) => {
      mutationRecords.forEach((record) => {
        if (record.attributeName === 'value') {
          inputElement.dispatchEvent(new Event('input'));
          updateButtonStates(record.target.value, this.buttonElements);
        }
      });
    });
    observer.observe(inputElement, { attributes: true });

    // Set initial value if any
    const cardSelectorValue = inputElement.getAttribute('value');
    if (cardSelectorValue && cardSelectorValue !== '') {
      this.value = cardSelectorValue;
    }

    this.buttonElements.forEach((buttonElement) => {
      if (buttonElement.dataset.state === 'inactive') {
        buttonElement.classList.add('at-card-selector__item--inactive');
        buttonElement.setAttribute('tabindex', -1);
      }

      buttonElement.addEventListener('keydown', (userEvent) => {
        const validSelectionKeycodes = [keyCode.RETURN, keyCode.SPACE];
        if (userEvent.keyCode !== keyCode.TAB && validSelectionKeycodes.includes(userEvent.keyCode)) handleSelection({ inputElement, buttonElement, userEvent });
      });

      buttonElement.addEventListener('click', (userEvent) => handleSelection({ inputElement, buttonElement, userEvent }));
    });
  }

  set value(value) {
    this.inputElement.setAttribute('value', value);
  }

  get value() {
    return this.inputElement.getAttribute('value');
  }
}

const initializeCardSelector = () => {
  document.querySelectorAll('[data-element="card-selector"]').forEach((cardSelectorElement) => new CardSelector(cardSelectorElement));
};

export {
  initializeCardSelector,
  CardSelector,
};
