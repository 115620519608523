import 'smooth-scroll/dist/smooth-scroll.polyfills';

const smoothScroll = {
  speed: 300,
  speedAsDuration: true,
};

['.at-page-index a'].forEach((e) => {
  // eslint-disable-next-line
  document.querySelector(e) ? new SmoothScroll(e, smoothScroll) : null;
});