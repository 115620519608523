//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Global consts
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

export const SCREENSIZE_SM = 640;
export const SCREENSIZE_LG = 1280;
export const SIDE_BAR_ANIMATION_DURATION = 300; // make sure to sync with $transition--duration (_sidemenu.scss)
export const HEADER_HEIGHT = '72'; // make sure to sync this with $height-rem--header (_globals.scss)

export const elementGrowler = document.querySelector('#at-growler');
export const elementSideMenu = document.querySelector('.at-menu__side-menu');
export const elementMenuToggleButton = document.querySelector('.at-menu .at-menu__button-toggle');
export const elementMenuCloseButton = document.querySelector('.at-menu .at-menu__button-close');
export const elementMenuBackButtons = document.querySelectorAll('.at-menu .at-menu__button-back');
export const elementAlphaBackground = document.querySelector('.at-menu__alpha-bg');
export const elementMenuInnerLevel = {
  1: document.querySelector('.at-menu__inner.at-menu__level-1'),
  2: document.querySelector('.at-menu__inner.at-menu__level-2'),
  3: document.querySelector('.at-menu__inner.at-menu__level-3'),
};
