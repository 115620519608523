// eslint-disable-next-line import/no-unresolved
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import KeyboardEvent from '../../event/js/keyboard';
import 'focus-visible';

/**
 * Zoomable Image
 *
 * Uses Photoswipe for features such as zoom, pan, gestures, etc, see https://photoswipe.com/
 * Opens full screen at the top most layer
 * Has to pass in image width and height information to the data element
 *
 * Zoom steps: 100%, 150%, 200%, 250%, 300%
 * Zooming while using gestures will match to the nearest zoom step (text) and snap to it when fingers lifted up
 *
 * Optional:
 * Has top and bottom bars with our own styling
 * Top bar has a close button and a title
 * Bottom bar has a zoom-level info and zoom-in and zoom-out buttons
 */

const headingTag = 'h1'; // default heading tag if not provided
const zoomSteps = [1, 1.5, 2, 2.5, 3]; // * 100%

const zoomableImageRoot = document.querySelector('.at-zoomable-image');
const zoomableImageContent = document.querySelector('.at-zoomable-image .at-zoomable-image__content'); // photoswipe content
const topBar = document.querySelector('.at-zoomable-image .at-zoomable-image__top-bar');
const bottomBar = document.querySelector('.at-zoomable-image .at-zoomable-image__bottom-bar');
const zoomOutButton = document.querySelector('.at-zoomable-image .at-zoomable-image__zoom-out-button');
const zoomInButton = document.querySelector('.at-zoomable-image .at-zoomable-image__zoom-in-button');
const closeButton = document.querySelector('.at-zoomable-image .at-zoomable-image__close-button');
const zoomLevel = document.querySelector('.at-zoomable-image .at-zoomable-image__zoom-level');

const options = { // Photoswipe options
  appendToEl: zoomableImageContent,

  zoom: false,
  close: false,
  counter: false,
  arrowPrev: false,
  arrowNext: false,
  wheelToZoom: false,
  initialZoomLevel: 'fit',
  secondaryZoomLevel: 'fit',
  showHideAnimationType: 'fade',
  imageClickAction: () => {},
  tapAction: () => {},
  bgClickAction: () => {},

  pswpModule: () => import('photoswipe'),

  getViewportSizeFn: () => { // image cropped issue fix
    let height = document.documentElement.clientHeight;
    if (bottomBar) height -= bottomBar.clientHeight;
    if (topBar) height -= topBar.offsetHeight;

    return {
      x: document.documentElement.clientWidth,
      y: height,
    };
  },
};

let bodyOverflowStyle = null; // store init body overflow style to restore after closing

let zoomStepsIndex = 0; // default to 100%

// Photoswipe zoom level
// set after image loaded or screen resized/rotated
// assume initial as 100% or 0 index
let initZoomLevel = 0;

// Gestures
let isPointerDown = false;
let isZoom = false;
let eventCache = []; // used for gestures check

// Set zoom level text, disabled button conditions
// Return Photoswipe zoom level
function getZoomLevel() {
  if (zoomStepsIndex <= 0) zoomStepsIndex = 0;
  if (zoomStepsIndex > zoomSteps.length - 1) { zoomStepsIndex = zoomSteps.length - 1; }

  if (zoomLevel) zoomLevel.innerHTML = `${zoomSteps[zoomStepsIndex] * 100}%`;

  if (zoomOutButton) {
    if (zoomStepsIndex === 0) {
      zoomOutButton.setAttribute('disabled', '');
      zoomInButton.focus();
    } else {
      zoomOutButton.removeAttribute('disabled');
    }
  }

  if (zoomInButton) {
    if (zoomStepsIndex === zoomSteps.length - 1) {
      zoomInButton.setAttribute('disabled', '');
      zoomOutButton.focus();
    } else {
      zoomInButton.removeAttribute('disabled');
    }
  }

  return initZoomLevel * zoomSteps[zoomStepsIndex];
}

function createZoomableImage(zoomableImageSelector) {
  const lightbox = new PhotoSwipeLightbox({
    ...options,
    gallery: zoomableImageSelector,
  });

  /**
   * Functions for event listeners
   */
  function closeLightbox() {
    lightbox.pswp.close();
  }

  function zoomOut() {
    zoomStepsIndex -= 1;
    lightbox.pswp.zoomTo(getZoomLevel());
  }

  function zoomIn() {
    zoomStepsIndex += 1;
    lightbox.pswp.zoomTo(getZoomLevel());
  }

  function resetZoom() {
    zoomStepsIndex = 0;
    lightbox.pswp.zoomTo(getZoomLevel());
  }

  function mouseWheel(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.deltaY < 0) {
      zoomStepsIndex += 1;
    } else {
      zoomStepsIndex -= 1;
    }
    lightbox.pswp.zoomTo(getZoomLevel());
  }

  function pointerDown(event) {
    event.preventDefault();
    eventCache.push(event);
    isPointerDown = true;
  }

  function pointerUp(event) {
    event.preventDefault();
    eventCache = [];
    isPointerDown = false;

    if (isZoom && lightbox.pswp.currSlide.currZoomLevel !== getZoomLevel()) {
      isZoom = false;
      lightbox.pswp.zoomTo(getZoomLevel());
    }
  }

  function pointerMove(event) {
    if (!isPointerDown) return;

    const { currZoomLevel } = lightbox.pswp.currSlide;

    if (currZoomLevel > initZoomLevel * zoomSteps[zoomSteps.length - 1] || currZoomLevel < initZoomLevel) {
      event.stopPropagation(); // stop if out of zoom range
    } else {
      const index = eventCache.findIndex((cache) => cache.pointerId === event.pointerId);
      eventCache[index] = event;

      // 2 fingers gesture
      if (eventCache.length === 2) {
        isZoom = true;

        // find nearest zoom step
        const currZoomStep = zoomSteps.reduce((prev, curr) => {
          const zoomStep = currZoomLevel / initZoomLevel;
          return Math.abs(curr - zoomStep) < Math.abs(prev - zoomStep) ? curr : prev;
        });

        zoomStepsIndex = zoomSteps.findIndex((zoomStep) => zoomStep === currZoomStep);
        if (zoomLevel) zoomLevel.innerHTML = `${currZoomStep * 100}%`;
      }
    }
  }

  function keyboardKeyDown(userEvent) {
    if (!KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB)) return;

    const focusableButtons = Array.from(zoomableImageRoot.querySelectorAll('.at-button'));

    if (!focusableButtons.length || focusableButtons.every((button) => button.disabled)) {
      userEvent.preventDefault();
      return;
    }

    let focusIndex = focusableButtons.findIndex((button) => button === userEvent.target);

    do {
      focusIndex = userEvent.shiftKey ? focusIndex - 1 : focusIndex + 1;
      if (focusIndex < 0) focusIndex = focusableButtons.length - 1;
      if (focusIndex > focusableButtons.length - 1) focusIndex = 0;
    } while (focusableButtons[focusIndex].disabled);

    focusableButtons[focusIndex].focus();
    userEvent.preventDefault();
  }

  /**
   * Photoswipe Lightbox events
   */
  lightbox.on('contentInit', ({ content }) => {
    zoomableImageRoot.classList.remove('at-zoomable-image--hidden');
    zoomableImageRoot.setAttribute('aria-hidden', false);

    bodyOverflowStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    if (topBar) {
      const title = document.createElement(content.data.element.dataset.titleStyle || headingTag);
      title.classList.add('at-zoomable-image__title', 'at-align-self-center', 'at-m-0', 'at-font__headline4');
      title.innerHTML = content.data.element.dataset.title || '';
      topBar.insertBefore(title, closeButton);
    }

    // Image cropped issue fix
    let height = document.documentElement.clientHeight;
    if (bottomBar) height -= bottomBar.clientHeight;
    if (topBar) height -= topBar.offsetHeight;
    zoomableImageContent.style.height = `${height}px`;
  });

  lightbox.on('contentAppend', ({ content }) => {
    content.element.setAttribute('alt', content.data.element.dataset.alt);
  });

  lightbox.on('contentActivate', () => {
    const placeholder = document.querySelector('.pswp .pswp__img--placeholder');
    placeholder.classList.add('at-d-flex', 'at-justify-content-center', 'at-align-items-center', 'at-font__headline4', 'at-color--tint-ocean-90');
    placeholder.innerHTML = 'Loading Image...';

    initZoomLevel = lightbox.pswp.currSlide.zoomLevels.initial;
    isPointerDown = false;
    isZoom = false;

    // Focus ring and tabbing issues fix
    const pswpRoot = document.querySelector('.pswp');
    pswpRoot.classList.add('at-position-relative');
    pswpRoot.removeAttribute('tabindex');
    pswpRoot.removeAttribute('role');

    // Image cropped issue fix
    const removedElements = document.querySelectorAll('.pswp .pswp__bg, .pswp__top-bar, .pswp__item:first-child, .pswp__item:last-child, .pswp__button, .pswp__icon, .pswp__icn, .pswp__counter');
    removedElements.forEach((element) => element.remove());

    const pswpContainer = document.querySelector('.pswp .pswp__container');
    pswpContainer.classList.add('at-position-relative');

    const pswpScrollWrap = document.querySelector('.pswp .pswp__scroll-wrap');
    pswpScrollWrap.classList.add('at-position-absolute', 'at-d-flex', 'at-flex-column');

    // Event listeners
    window.addEventListener('resize', resetZoom);
    zoomableImageRoot.addEventListener('keydown', keyboardKeyDown);
    zoomableImageContent.addEventListener('wheel', mouseWheel);
    zoomableImageContent.addEventListener('pointerdown', pointerDown);
    zoomableImageContent.addEventListener('pointerup', pointerUp);
    zoomableImageContent.addEventListener('pointermove', pointerMove);

    if (zoomInButton) {
      zoomInButton.addEventListener('click', zoomIn);
      zoomInButton.removeAttribute('tabindex');
    }
    if (zoomOutButton) {
      zoomOutButton.addEventListener('click', zoomOut);
      zoomOutButton.removeAttribute('tabindex');
    }
    if (closeButton) {
      closeButton.addEventListener('click', closeLightbox);
      closeButton.removeAttribute('tabindex');
    }

    lightbox.pswp.zoomTo(getZoomLevel());

    zoomableImageRoot.setAttribute('role', 'dialog');
    zoomableImageRoot.setAttribute('tabindex', '0');
    zoomableImageRoot.focus();
  });

  lightbox.on('contentResize', ({ content }) => {
    const { slide, state } = content;

    if (slide && slide.zoomLevels.initial !== initZoomLevel) initZoomLevel = slide.zoomLevels.initial;

    // Image cropped issue fix
    let height = document.documentElement.clientHeight;
    if (bottomBar) height -= bottomBar.clientHeight;
    if (topBar) height -= topBar.offsetHeight;
    zoomableImageContent.style.height = `${height}px`;

    // Need to set error font here otherwise will be reverted back
    if (state === 'error') {
      const errorMessage = document.querySelector('.pswp__error-msg');
      errorMessage.classList.add('at-font__headline4');
    }
  });

  // This event is only triggerred on error not sure why
  lightbox.on('loadComplete', ({ content }) => {
    if (content.state === 'error') {
      const title = document.querySelector('.at-zoomable-image__title');
      if (title) title.innerHTML = content.data.element.dataset.title ? `${content.data.element.dataset.title} - Error loading image` : 'Error loading image';

      if (zoomInButton) {
        zoomInButton.setAttribute('disabled', true);
        zoomInButton.removeEventListener('click', zoomIn);
        zoomInButton.setAttribute('tabindex', -1);
      }
      if (zoomOutButton) {
        zoomInButton.setAttribute('disabled', true);
        zoomOutButton.removeEventListener('click', zoomOut);
        zoomOutButton.setAttribute('tabindex', -1);
      }

      window.removeEventListener('resize', resetZoom);
      zoomableImageContent.removeEventListener('wheel', mouseWheel);
      zoomableImageContent.removeEventListener('pointerdown', pointerDown);
      zoomableImageContent.removeEventListener('pointerup', pointerUp);
      zoomableImageContent.removeEventListener('pointermove', pointerMove);
    }
  });

  lightbox.on('close', () => {
    window.removeEventListener('resize', resetZoom);
    zoomableImageRoot.removeEventListener('keydown', keyboardKeyDown);
    zoomableImageContent.removeEventListener('wheel', mouseWheel);
    zoomableImageContent.removeEventListener('pointerdown', pointerDown);
    zoomableImageContent.removeEventListener('pointerup', pointerUp);
    zoomableImageContent.removeEventListener('pointermove', pointerMove);

    if (zoomInButton) {
      zoomInButton.removeEventListener('click', zoomIn);
      zoomInButton.setAttribute('tabindex', -1);
    }
    if (zoomOutButton) {
      zoomOutButton.removeEventListener('click', zoomOut);
      zoomOutButton.setAttribute('tabindex', -1);
    }
    if (closeButton) {
      closeButton.removeEventListener('click', closeLightbox);
      closeButton.setAttribute('tabindex', -1);
    }

    zoomableImageRoot.classList.add('at-zoomable-image--hidden');
    zoomableImageRoot.setAttribute('aria-hidden', true);
    zoomableImageRoot.setAttribute('tabindex', '-1');
    zoomableImageRoot.removeAttribute('role');

    const title = document.querySelector('.at-zoomable-image__title');
    if (title) title.remove();

    document.body.style.overflow = bodyOverflowStyle === '' ? null : bodyOverflowStyle;
    zoomStepsIndex = 0;
  });

  lightbox.init();
}

const initializeZoomableImage = () => {
  document.querySelectorAll('[data-element="zoomable-image"]').forEach((zoomableImageSelector) => createZoomableImage(zoomableImageSelector));
};

export default initializeZoomableImage;