// "popoutTriggerElement" is the element which will trigger the actual pop up.
// ".at-popout-trigger" should be add to an HTML tag such as button, a, or any interactive element.
// This element will also contain all the data-attr with the config params as explained below.

const popout = (dataElement, params) => {
  const popoutTriggerElement = document.querySelectorAll(`[data-popout-trigger=${dataElement}]`);
  popoutTriggerElement.forEach((ele) => {
    // Default props which will be passed through to the tippy instance unless overwritten by custom params.
    // Examples of how to overwrite them next to each property.
    let defaultConfigParams = {
      // "theme" allows us to add custom "themes" or "styles". They should be provided space separated as per docs.
      duration: 150,
      theme: 'at', // data-theme
      trigger: 'click', // data-trigger
      interactive: true, // data-content
      placement: 'bottom', // data-placement
      maxWidth: 'none',
    };
    defaultConfigParams = Object.assign(defaultConfigParams, params);
    // In order to overwrite or add params they should be provided as HTML data attributes as exemplified above.
    // The valid options are all the ones provided by Tippy.js
    // https://atomiks.github.io/tippyjs/all-props/
    const configParams = {
      ...defaultConfigParams, // default params
      ...ele.dataset, // custom params
      content: document.querySelector(ele.dataset.content),
    };

    tippy(
      ele,
      configParams,
    );
  });
};

export default popout;