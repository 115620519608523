/**
 * Event handling
 * @param dataElement - Element which contains dataset and represents the trigger.
 * @param userEvent - Event to be attached to the element in charge of trigger the action.
 * @param customMethod - Whatever action required is will come in this parameter as a function and will receive the trigger & target element as a param to make it a concert of every specific use case.
 */

class event {
}
event.eventHandler = (dataElement, userEvent, customMethod) => {
  document.querySelectorAll(`[data-element*=${dataElement}]`)
    .forEach((eachElement) => eachElement.addEventListener(userEvent, (ev) => {
      const target = eachElement.getAttribute(`data-${dataElement}-target`);
      document.querySelectorAll(target)
        .forEach((targetElement) => customMethod(eachElement, targetElement, ev));
    }));
};

export default event;
