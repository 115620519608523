// Use by template/html with data-active/data-collapse attributes
// eslint-disable-next-line
import { collapse } from '../../accordion/js/module';

const isVisible = (el) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  return elemTop > 0 && elemBottom > 0;
};

export default document.querySelectorAll("[data-sticky='parent']")
  .forEach((stickyParent) => {
    stickyParent.querySelectorAll("[data-sticky='bottom']")
      .forEach((stickyElement) => {
        const bottomClone = stickyElement.cloneNode(true);
        const stickyContainer = document.createElement('div');

        stickyContainer.className = 'at-sticky-container';
        stickyContainer.append(bottomClone);
        document.body.append(stickyContainer);

        window.addEventListener('scroll', () => {
          if (isVisible(stickyElement)) {
            document.body.style['padding-bottom'] = null;

            // Toggle make the changes flickers
            if (stickyContainer.classList.contains('active')) {
              stickyContainer.classList.remove('active');
              stickyParent.classList.add('active');
            }
          } else {
            const style = stickyContainer.currentStyle || window.getComputedStyle(stickyContainer);
            const height = stickyContainer.offsetHeight + parseInt(style.marginTop, 10);

            document.body.style['padding-bottom'] = `${height}px`;

            // Toggle make the changes flickers
            if (!stickyContainer.classList.contains('active')) {
              stickyContainer.classList.add('active');
              stickyParent.classList.remove('active');
            }
          }
        });
      });
  });
