import { DateTime } from 'luxon';

/*
    This is the CCC unauthenticated store
    - If making changes in the actual CCC store, might need to update the CCC layout store as well for screenshot testing purposes.
*/

export default () => ({
  state: {
    selectedAccountDetail: null,
  },
  showFormSubmissionSpinner: false,
  addressFinderStore: null,
  accessibilityFocus: false,

  init() {
    if (window.location.search === '?source=entry_page') {
      const hasErrorBanner = document.querySelector('.validation-summary-errors');
      if (!hasErrorBanner) {
        this.accessibilityFocus = true;
        this.$nextTick(() => this.$refs.refAccessibilityMainContent.focus());
      }
    }
  },

  setAddressFinderStore(store) {
    this.addressFinderStore = store;
  },

  showDifferentDeliveryAddressInput() {
    return false;
  },

  showDifferentDeliveryAddressCheckbox() {
    return false;
  },

  displayDate(dateStr) { return DateTime.fromISO(dateStr).toFormat('dd/MM/yyyy'); },

  resetFormValidation() {
    const validationSummary = $('.validation-summary');

    // if API return more than one error code, only retain one validation summary after clientside validation
    const totalValidationSummary = validationSummary.length;
    if (totalValidationSummary > 1) {
      for (let i = 1; i < totalValidationSummary; i += 1) {
        validationSummary[i].remove();
      }
    }

    validationSummary.removeClass('at-border--warning', 'at-background-color--warning').addClass('at-border--error', 'at-background-color--error');
    validationSummary.removeClass('at-background-color--warning').addClass('at-background-color--error');
    validationSummary.attr({ role: 'alert', tabindex: 0 });
    validationSummary.find('.at-mt-0.at-mb-2.at-font__headline6').text('The form could not be submitted.');
    validationSummary.find('.at-mt-0.at-font__body2').text('Please change the errors below and try again:');
    validationSummary.find('.at-link.at-d-inline-flex').remove();
    validationSummary.removeClass('validation-summary-errors').addClass('validation-summary-valid');
    document.querySelector('.validation-summary .at-icon').classList.replace('at-icon--warning', 'at-icon--error');

    if (validationSummary.find('ul').length <= 0) {
      validationSummary.find('.at-mt-0.at-font__body2').append('<ul class="at-mt-2 at-mb-0 at-ps-5"></ul>');
    }

    const form = $('#communityConnectConcessionForm');

    form.validate().resetForm();

    form.find('[data-valmsg-replace]')
      .removeClass('field-validation-error')
      .addClass('field-validation-valid')
      .empty();
  },

  validateForm() {
    this.resetFormValidation();

    if ($('#communityConnectConcessionForm').valid()) {
      return true;
    }

    const validationSummary = $('.validation-summary');
    const validator = $('#communityConnectConcessionForm').validate();
    const list = validationSummary.find('ul').empty();

    $.each(validator.errorMap, (index, value) => {
      const displayName = $(`#${index.replace('.', '_')}`).attr('data-display-name');
      const errorMessage = value.replace(`The field ${displayName}`, '').replace(displayName, '');
      list.append(`<li class="at-mt-0 at-mb-1 at-font__body2"><a class="at-link at-link__text" href="#${index.replace('.', '_')}">${displayName}</a>${errorMessage}</li>`);
    });

    this.$nextTick(() => {
      $('.validation-summary').focus();
    });

    return false;
  },

  handleSubmit() {
    if (!this.validateForm()) {
      return false;
    }

    this.showFormSubmissionSpinner = true;
    this.$nextTick(() => this.$refs.refLoadingSpinner.focus());
    $('#communityConnectConcessionForm').submit();
    return true;
  },
});
