import 'focus-visible';
import 'smooth-scroll/dist/smooth-scroll.polyfills';
import 'gumshoejs/dist/gumshoe.polyfills';
import '../../growler/js/module';
import '../../user-button/js/module';
// Use by template/html with data-active/data-collapse attributes
// eslint-disable-next-line
import { collapse, active } from '../../accordion/js/module'; 

// Use by content-block, index-menu, and back-to-top
const indexMenuOptions = {
  gumshoe: {
    navClass: 'at-index-menu__item--active',
  },
  smoothScroll: {
    speed: 300,
    speedAsDuration: true,
  },
};

['.at-index-menu a[href*="#"]', '.at-back-to-top__nav'].forEach((e) => {
  // eslint-disable-next-line
  document.querySelector(e) ? new SmoothScroll(e, indexMenuOptions.smoothScroll) : null;
});

['.at-index-menu a'].forEach((e) => {
  // eslint-disable-next-line
  document.querySelector(e) ? new Gumshoe(e, indexMenuOptions.gumshoe) : null;
});

// Press Escape to close active elements
const activeElements = document.querySelectorAll('[data-close-key="Escape"]');
document.addEventListener('keydown', (event) => {
  const key = event.key || event.keyCode;
  if (key === 'Escape' || key === 'Esc' || key === 27) {
    activeElements.forEach((el) => {
      if (el.classList.contains('active')) {
        el.click();
        // Incase it doesn't toggle active class
        el.classList.remove('active');
      }
    });
  }
});
