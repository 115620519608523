/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import authenticatedStore, { defaultValues } from './store_authenticated.js';

// !!WARNING!!
// Please do not use the 'destructuring assigment' for functions and assigning values, it will not work as expected
// The 'destructuring assignment' can only be used as a look-up only
//
// NOTE: parent refers to store_authenticated properties
//       (similar to inheritance) so please be aware of overwriting variables and / or functions
//       If making changes in the actual CCC store, might need to update the CCC layout store as well for screenshot testing purposes.
//
const initAuthenticatedWithoutCard = (parent) => {
  // Init snapshot states
  if (!parent.hydratedFromSavedState) { // prevent savedState being overwritten
    const step1State = {
      isRegisterNewCard: defaultValues.isRegisterNewCard,
      selectedCustomerId: defaultValues.selectedCustomerId,
      selectedCardNumber: defaultValues.selectedCardNumber,
    };
    parent.setState({ step1State });
  }

  // Focus on error field after alert clicked or pressed
  parent.showErrorField = () => {
    parent.setFocus('refAccountSelection');
  };

  parent.clearStep2DataIfStateChanged = async ({ step1State, isRegisterNewCard }) => {
    if (parent.isStateChanged({ step1State })) {
      parent.setState({ useDifferentDeliveryAddressChecked: false });
      parent.setDispatch('collapsemanualaddressfinder');
      parent.setDispatch('clearaddressvalue');
      parent.clearAddressValue();

      await parent.awaitNextTick();
      document.getElementById('MSDClientId').value = '';
      document.getElementById('IsTCsRead').checked = false;
      document.getElementById('IsConsentProvided').checked = false;

      if (!isRegisterNewCard) {
        parent.setState({
          selectedAccountDetail: defaultValues.selectedAccountDetail, // IMPORTANT! parent linke have to be first to trigger re-rendering to avoid race condition of LastName not appearing
          selectedCustomerId: defaultValues.selectedCustomerId,
        });

        parent.clearFormFields();
      }
    }
  };

  parent.isValidToStep2 = ({
    state: { isRegisterNewCard, selectedCustomerId }, accounts,
  }) => {
    if (!isRegisterNewCard) return true;

    if (selectedCustomerId) {
      parent.setState({ selectedAccountDetail: accounts.find((account) => account.customerid === selectedCustomerId) });
      const numberOfCardsForSelectedAccount = parent.state.selectedAccountDetail.cardCount;
      if (numberOfCardsForSelectedAccount < 9) {
        return true;
      }
      parent.showSimpleAlert('Your selected account reached the limit for the number of AT HOP Cards', 'Please select another account or add a new linked account');
      return false;
    }

    parent.showSimpleAlert(null, 'Please choose an account to continue with your application');
    return false;
  };

  parent.handleContinue = async () => {
    const { currentStep } = parent.state;

    if (currentStep === 1 && parent.isValidToStep2(parent)) {
      await parent.clearStep2DataIfStateChanged(parent.state);
      parent.ga4Event('select_preregistration', { preregistration: parent.state.isRegisterNewCard });
      await parent.next();
    }
  };
};

export default (numberOfSteps, savedStateElementId, completeSummaryElementId, ga4MeasurementId) => authenticatedStore(numberOfSteps, savedStateElementId, completeSummaryElementId, initAuthenticatedWithoutCard, ga4MeasurementId);