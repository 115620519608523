import 'focus-visible';

const newtemplateTag = '#main';
const oldtemplateTag = '#AT_Main_body';
const skipNavigationEl = document.querySelector('.at-skip-to-main-content');

const goToMainContent = (keyboard = false) => {
  // Wayfinding on BTF page has a url monitoring/routing that will override any invalid urls
  const wayfindingEl = document.querySelector('#at-wayfinding');
  if (wayfindingEl) {
    const oldTemplateEl = document.querySelector('#AT_Public_Transport');
    oldTemplateEl.setAttribute('tabindex', '0');
    oldTemplateEl.focus();
    oldTemplateEl.removeAttribute('tabindex');
    if (keyboard) wayfindingEl.classList.add('wayfinding--keyboard-active');
  } else {
    // Look for new template tag first
    const tag = document.querySelector(newtemplateTag) ? newtemplateTag : oldtemplateTag;
    // HOP has its own routing
    const locationReplacement = document.querySelector('.at-my-hop') ? `${window.location.pathname}${tag}` : tag;
    window.location.replace(locationReplacement);
  }
};

if (skipNavigationEl) {
  skipNavigationEl.addEventListener('click', goToMainContent);
  skipNavigationEl.addEventListener('keypress', (e) => {
    e.preventDefault();
    if (e.keyCode === 13 || e.key === 'Enter') {
      goToMainContent(true);
    }
  });
}
