/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
// Somehow, it does not work when not done this way
function gtag() {
  window.dataLayer.push(arguments);
}

export default class CCCGoogleAnalytics4 {
  constructor(ga4Account) {
    if (!ga4Account) throw new Error('Mandatory ga4Account is not defined');

    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    this.ga4Account = ga4Account;

    // If using debug console in ga4: gtag('config', ga4Account, { debug_mode: true });
    gtag('config', ga4Account);
  }

  event(eventName, eventProperties, virtual_url) {
    const prop = {
      ...eventProperties,
      app_name: 'community_connect',
      event_source: 'app_code',
      send_to: this.ga4Account,
    };
    if (virtual_url) prop.virtual_url = virtual_url;

    gtag('event', eventName, prop);
  }

  pageView(page_title, page_location, page_path) {
    const props = {
      page_title,
      page_location,
      page_path,
      virtual_url: page_path,
      event_source: 'app_code',
      send_to: this.ga4Account,
    };

    gtag('event', 'page_view', props);
  }
}
