/* eslint-disable import/extensions */
import 'focus-visible/dist/focus-visible.min.js';
import KeyboardEvent from '../../event/js/keyboard';
/**
 * Collapsible Dropdown Listbox
 * An accessible dropdown component developed by following the guide from W3C (https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 * Consists of a hidden input, a button, and a list of options
 *
 * How to implement:
 * - It needs certain elements with attributes and classes for the styling, please look at the EJS template as a guide
 *
 * - For the JS part, basically it needs to create an instance of Dropdown class and assign it to the target input element
 *   There are 2 options:
 *    1. Use initializeDropdown()
 *       This is a global implementation
 *       It assigns an instance of Dropdown class to every input element that has a `data-element="dropdown"` attribute
 *       Example: look at the EJS template
 *
 *    2. Explicitly create and assign an instance of Dropdown class for each target input element
 *       This is a more specific implementation
 *       Example: look at `init dropdown` in store_authenticated.js in ccc-core
 *       In at_website there is a Dropdown.cshtml partial view for the CCC template
 *
 * Event listeners:
 * - Input element: onInput
 * - Button element: onChange
*/
const isValidItem = (item) => item && item.getAttribute('role') === 'option' && !item.classList.contains('empty');

const isExpandedWithCallback = (buttonElement, callback) => {
  if (buttonElement.getAttribute('aria-expanded') === 'true') return true;

  callback();
  return false;
};

const updateButtonInnerText = (buttonElement, { value }) => {
  const textElement = document.getElementById(`${buttonElement.dataset.dropdownButtonFor}-text`);
  let innerText = buttonElement.dataset.dropdownPlaceholder;

  if (value) {
    const selectedItem = document.getElementById(value);
    innerText = selectedItem.innerText;
  }

  textElement.innerText = innerText;
};

const setSelectedItem = (dataset, selectedItem) => {
  const {
    buttonElement,
    listElement,
    inputElement,
    keyboardNavigation,
  } = dataset;

  const activeDescendant = listElement.getAttribute('aria-activedescendant');

  if (activeDescendant) {
    const oldItem = document.getElementById(activeDescendant);
    oldItem.removeAttribute('aria-selected');
    oldItem.classList.remove('aria-selected');
  }

  if (keyboardNavigation) {
    buttonElement.blur();
    selectedItem.classList.add('aria-selected');
  }

  listElement.setAttribute('aria-activedescendant', selectedItem.id);
  listElement.querySelectorAll('[role="option"]').forEach((element) => element.classList.remove('selected'));

  selectedItem.setAttribute('aria-selected', 'true');
  selectedItem.classList.add('selected');

  inputElement.setAttribute('value', selectedItem.id);

  // Scroll the list element if selected item is not in the list view
  if (listElement.scrollHeight > listElement.clientHeight) {
    const scrollBottom = listElement.clientHeight + listElement.scrollTop;
    const selectedItemBottom = selectedItem.offsetTop + selectedItem.offsetHeight;
    let scrollTopValue = listElement.scrollTop;

    if (selectedItemBottom > scrollBottom) {
      scrollTopValue = selectedItemBottom - listElement.clientHeight;
    } else if (selectedItem.offsetTop < listElement.scrollTop) {
      scrollTopValue = selectedItem.offsetTop;
    }

    // eslint-disable-next-line no-param-reassign
    listElement.scrollTop = scrollTopValue;
  }
};

const collapseList = (dataset) => {
  const {
    buttonElement,
    listElement,
    iconElement,
    keyboardNavigation,
  } = dataset;

  if (isExpandedWithCallback(buttonElement, () => {})) {
    buttonElement.setAttribute('aria-expanded', 'false');
    listElement.classList.replace('at-dropdown__list--expanded', 'at-dropdown__list--collapsed');
    iconElement.classList.replace('chevron--top', 'chevron--bottom');

    if (keyboardNavigation) buttonElement.classList.add('focus-visible');

    listElement.blur();
    buttonElement.focus();
  }
};

const expandList = (dataset) => {
  const {
    buttonElement,
    listElement,
    inputElement,
    iconElement,
    keyboardNavigation,
  } = dataset;

  buttonElement.setAttribute('aria-expanded', 'true');
  listElement.classList.replace('at-dropdown__list--collapsed', 'at-dropdown__list--expanded');
  iconElement.classList.replace('chevron--bottom', 'chevron--top');

  if (!keyboardNavigation) listElement.querySelectorAll('[role="option"]').forEach((element) => element.classList.remove('aria-selected'));

  listElement.focus();

  let selectedItem = document.getElementById(inputElement.value);

  // If no item selected, auto select first item
  if (!selectedItem) {
    const firstItem = listElement.querySelector('.at-dropdown__item, [role="option"]');
    if (firstItem) selectedItem = firstItem;
  }

  if (isValidItem(selectedItem)) setSelectedItem(dataset, selectedItem);
};

// Expand or collapse
const toggleList = (dataset) => {
  const { buttonElement, userEvent } = dataset;

  userEvent.preventDefault();

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    collapseList(dataset);
  }
};

const moveUpItem = (dataset) => {
  const { buttonElement, inputElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    if (!inputElement.value) return;

    const currentItem = document.getElementById(inputElement.value);
    const prevItem = currentItem.previousElementSibling;

    if (isValidItem(prevItem)) setSelectedItem(dataset, prevItem);
  }
};

const moveDownItem = (dataset) => {
  const { buttonElement, inputElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    if (!inputElement.value) return;

    const currentItem = document.getElementById(inputElement.value);
    const nextItem = currentItem.nextElementSibling;

    if (isValidItem(nextItem)) setSelectedItem(dataset, nextItem);
  }
};

const moveToFirstItem = (dataset) => {
  const { buttonElement, listElement, inputElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    if (!inputElement.value) return;

    const firstItem = listElement.querySelector('[role="option"]');

    if (isValidItem(firstItem)) setSelectedItem(dataset, firstItem);
  }
};

const moveToLastItem = (dataset) => {
  const { buttonElement, listElement, inputElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    if (!inputElement.value) return;

    const lastItem = listElement.lastElementChild;

    if (isValidItem(lastItem)) setSelectedItem(dataset, lastItem);
  }
};

const handleTabKeyCode = (dataset) => {
  const { buttonElement } = dataset;

  buttonElement.classList.add('focus-visible');

  if (isExpandedWithCallback(buttonElement, () => buttonElement.classList.add('focus-visible'))) {
    collapseList(dataset);
  }
};

const handleItemClicked = (dataset) => {
  const { userEvent } = dataset;
  const selectedItem = userEvent.target;

  userEvent.preventDefault();

  if (isValidItem(selectedItem)) {
    setSelectedItem(dataset, selectedItem);
    collapseList(dataset);
  }
};

const handleKeyPressed = (dataset) => {
  const { userEvent } = dataset;

  switch (true) {
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.UP):
      userEvent.preventDefault();
      moveUpItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.DOWN):
      userEvent.preventDefault();
      moveDownItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.HOME):
      userEvent.preventDefault();
      moveToFirstItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.END):
      userEvent.preventDefault();
      moveToLastItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.SPACE):
      userEvent.preventDefault();
      expandList(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.BACKSPACE):
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.ESC):
      userEvent.preventDefault();
      collapseList(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.ENTER):
      userEvent.preventDefault();
      toggleList(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB):
      handleTabKeyCode(dataset);
      break;
    default:
      break;
  }
};

class Dropdown {
  constructor(inputElement) {
    this.inputElement = inputElement;
    this.buttonElement = document.querySelector(`[data-dropdown-button-for=${this.inputElement.id}]`);
    this.listElement = document.querySelector(`[data-dropdown-list-for=${this.inputElement.id}]`);
    this.iconElement = document.querySelector(`[data-dropdown-icon-for=${this.inputElement.id}]`);

    this.dataset = {
      buttonElement: this.buttonElement,
      listElement: this.listElement,
      inputElement: this.inputElement,
      iconElement: this.iconElement,
      keyboardNavigation: true,
    };

    // Use MutationObserver to detect an input value change then update button's innerText and dispatch events
    const observer = new MutationObserver((mutationRecords) => {
      mutationRecords.forEach((record) => {
        if (record.attributeName === 'value') {
          updateButtonInnerText(this.buttonElement, record.target);
          this.buttonElement.dispatchEvent(new Event('change'));
          this.inputElement.dispatchEvent(new Event('input'));
        }
      });
    });
    observer.observe(this.inputElement, { attributes: true });

    // Set initial value if any
    const dropdownValue = this.inputElement.getAttribute('value');
    if (dropdownValue && dropdownValue !== '') {
      this.value = dropdownValue;
    }

    // Specific event listener to handle TAB (on first focus)
    this.buttonElement.addEventListener('keyup', (userEvent) => {
      if (KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB)) handleKeyPressed({ ...this.dataset, userEvent });
    });

    this.buttonElement.addEventListener('keydown', (userEvent) => {
      if (!KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB)) handleKeyPressed({ ...this.dataset, userEvent });
    });

    this.buttonElement.addEventListener('mousedown', (userEvent) => toggleList({ ...this.dataset, userEvent, keyboardNavigation: false }));

    this.listElement.addEventListener('click', (userEvent) => handleItemClicked({ ...this.dataset, userEvent, keyboardNavigation: false }));
    this.listElement.addEventListener('keydown', (userEvent) => handleKeyPressed({ ...this.dataset, userEvent }));
    this.listElement.addEventListener('blur', () => collapseList({ ...this.dataset, keyboardNavigation: false }));
  }

  set value(value) {
    this.inputElement.setAttribute('value', value);
  }

  get value() {
    return this.inputElement.getAttribute('value');
  }

  addButtonEventListener(event, listener) {
    this.buttonElement.addEventListener(event, listener);
  }

  addListEventListener(event, listener) {
    this.listElement.addEventListener(event, listener);
  }

  expand(keyboardNavigation = false) {
    this.dataset.keyboardNavigation = keyboardNavigation;
    expandList(this.dataset);
  }

  collapse(keyboardNavigation = false) {
    this.dataset.keyboardNavigation = keyboardNavigation;
    collapseList(this.dataset);
  }
}

const initializeDropdown = () => {
  document.querySelectorAll('[data-element="dropdown"]').forEach((dropdownElement) => new Dropdown(dropdownElement));
};

export {
  Dropdown,
  initializeDropdown,
};
