import 'focus-visible';
import event from '../../event/js/module';

/**
 * Toggle active class
 * @param dataElement - element which contains dataset and represents the trigger.
 * @param targetElement - element which be affected once the event is triggered.
 * @param userEvent - event returned on userEvent trigger.
*/

export default (() => {
  const ariaLabels = ['aria-expanded'];

  event.eventHandler(
    'active',
    'click',
    (dataElement, targetElement, userEvent) => {
      userEvent.preventDefault();

      // choose whether you want to control aria-expanded in your toggler by setting data-expandable to true (optional)
      // when data-expandable is used, we can have a data-expandable-target to setup a target. If not setup, current target will be used
      if (dataElement.dataset.expandable) {
        const elementDataExpandableStatus = dataElement.getAttribute('aria-expanded');
        const isDataElementExpanded = elementDataExpandableStatus !== 'true';
        const expandableTarget = dataElement.dataset.expandableTarget;

        if (expandableTarget) {
          document.querySelectorAll(expandableTarget).forEach((expandableElement) => {
            const expandable = expandableElement;
            const isCollapsedGetter = () => expandable.getAttribute('aria-expanded') !== 'true';
            const isCollapsedInitialState = isCollapsedGetter();
            ariaLabels.forEach((label) => expandable.setAttribute(label, `${isCollapsedInitialState}`));

            if (expandable.dataset.accessibilityHide === 'true') {
              if (expandable.hidden) {
                expandable.hidden = !isCollapsedInitialState;
              }
              const transitionEndHandler = () => {
                expandable.hidden = isCollapsedGetter();// need to get an up to date value via the accessor, as opposed to a variable from an outer scope, because there's possibility for change in the interim.
                expandable.removeEventListener('transitionend', transitionEndHandler);
              };

              expandable.addEventListener('transitionend', transitionEndHandler);
            }
          });
        }
        ariaLabels.forEach((label) => dataElement.setAttribute(label, isDataElementExpanded));
      }

      // The class needs to be toggled "asynchronously", otherwise any associate animation may not play out after the "hidden" attribute or "display:none" style is cleared prior.
      setTimeout(() => targetElement.classList.toggle('active'));
    },
  );
})();
