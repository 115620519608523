import { isFieldContainsValue } from '../../utilities/js/module';

const addressFinderStore = (inputFieldId, addressStoredStateElementId, key, country = 'NZ', autoload = true) => ({
  ADDRESS_FINDER_KEY: key,
  manualAddressFinder: false,
  address_loading: false,
  textSpinnerTimeout: 500,
  showFormSubmissionSpinner: false,

  toggleAddressInput() {
    this.manualAddressFinder = !this.manualAddressFinder;
  },

  clearAddressValue() {
    this.deliveryAddress = '';
    this.$nextTick(() => { this.$refs.address_input.focus(); });
  },

  handleKeyUp() {
    this.$nextTick(() => {
      this.typingTimer = undefined;
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.address_loading = false;
      }, this.textSpinnerTimeout);
    });
  },

  handleKeyDown(value) {
    this.$nextTick(() => {
      const escapeKey = 'escape';
      const specialCharacters = ['backspace', 'delete'];

      if (this.deliveryAddress.length && value.toLowerCase() === escapeKey) {
        this.deliveryAddress = '';
        return true;
      }

      if (this.deliveryAddress.length > 0 && (value.length === 1 || specialCharacters.includes(value.toLowerCase()))) {
        this.address_loading = true;
        return true;
      }

      return false;
    });
  },

  init() {
    const { value } = document.getElementById(addressStoredStateElementId);

    if ((value || '').length > 0) {
      this.addressStoredState = value;
    } else {
      this.deliveryAddress = '';
      this.streetAddress = '';
      this.unitNumber = '';
      this.buildingName = '';
      this.suburb = '';
      this.city = '';
      this.postCode = '';
    }

    if (autoload) this.onLoad();
  },

  onLoad() {
    let addressResultElement = document.querySelector('.at-search__address-result');

    if (!addressResultElement) {
      addressResultElement = document.createElement('div');
      addressResultElement.className = 'at-search__address-result';
      document.querySelector('#ccc-address-finder .at-search__container').append(addressResultElement);
    }

    const widget = new AddressFinder.Widget(
      document.getElementById(inputFieldId),
      this.ADDRESS_FINDER_KEY,
      country,
      {
        address_params: { delivered: '1' },
        container: addressResultElement,
        list_class: 'at-search__result-list',
        item_class: 'at-search__result-item',
        hover_class: 'at-search__result-hover',
        position: 'static',
        show_locations: false,
      },
    );

    widget.on('result:select', (selectedAddress, item) => {
      const parsedSearchResult = this.parseAddress(item);
      this.streetAddress = parsedSearchResult.streetAddress;
      this.unitNumber = parsedSearchResult.unitNumber;
      this.buildingName = parsedSearchResult.buildingName;
      this.suburb = parsedSearchResult.suburb;
      this.city = parsedSearchResult.city;
      this.postCode = parsedSearchResult.postCode;
      this.deliveryAddress = selectedAddress;
    });
    this.widget = widget;
  },

  parseAddress(item) {
    const searchResult = {
      unitNumber: '',
      buildingName: '',
      streetAddress: '',
      suburb: '',
      city: '',
      postCode: '',
    };

    // PO Box address
    if (isFieldContainsValue(item.box_type)) {
      searchResult.streetAddress = (item.box_type && item.number) ? (`${item.box_type} ${item.number}`) : item.a;
      searchResult.suburb = isFieldContainsValue(item.lobby_name) ? item.lobby_name : (item.suburb || '');
    } else if (isFieldContainsValue(item.rd_number)) { // Address is Rural Delivery
      searchResult.streetAddress = `${item.number + (item.alpha || '')} ${item.street}`;
      searchResult.suburb = `RD ${item.rd_number}`;
    } else { // Other address
      searchResult.streetAddress = `${item.number + (item.alpha || '')} ${item.street}`;
      searchResult.suburb = item.suburb || item.city;
    }

    // Other fields
    searchResult.unitNumber = `${item.unit_type || ''} ${item.unit_identifier || ''}`;
    searchResult.buildingName = (isFieldContainsValue(item.floor) ? (`${item.floor}, `) : '') + (item.building_name || '');
    searchResult.city = item.mailtown || item.city;
    searchResult.postCode = item.postcode;

    return searchResult;
  },

  get addressStoredState() {
    const {
      deliveryAddress, unitNumber, buildingName, streetAddress, suburb, city, postCode, manualAddressFinder,
    } = this;
    return JSON.stringify({
      deliveryAddress, unitNumber, buildingName, streetAddress, suburb, city, postCode, manualAddressFinder,
    });
  },

  set addressStoredState(savedString) {
    const savedState = JSON.parse(savedString);
    Object.assign(this, savedState);
  },

});

export default addressFinderStore;