//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// This module is to handle variety of viewports or screen sizes/scrolls mostly for mobile devices
// It will also override height styles defined on SCSS files (_sidemenu.scss and menu.scss)
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

import { HEADER_HEIGHT, SCREENSIZE_LG, elementGrowler } from './globals';

const elementGrowlerCloseButton = elementGrowler?.querySelector('.at-growler__button');
const atMenuInners = document.querySelectorAll('.at-menu .at-menu__inner');

const isLargeScreenAndLevel1Menu = (inner) => window.innerWidth < SCREENSIZE_LG || (window.innerWidth >= SCREENSIZE_LG && inner.classList.contains('at-menu__level-1'));
// On LG and above screen sizes, the top part of menu for L1 has a close button
// this impacts menu inner height and scroll bar if exists

class ViewportImpact {
  static addGrowlerCloseButtonEventListener = () => {
    elementGrowlerCloseButton.addEventListener('click', () => {
      atMenuInners.forEach((inner) => {
        const { style } = inner;
        style.height = isLargeScreenAndLevel1Menu(inner) ? `${window.innerHeight + window.scrollY - HEADER_HEIGHT}px` : '100vh';
      });
    });
  };

  static updateMenuInnersHeight() {
    const growlerHeight = elementGrowler ? elementGrowler.clientHeight : 0;
    atMenuInners.forEach((inner) => {
      const offsetHeight = window.innerHeight + window.scrollY - growlerHeight;
      const { style } = inner;
      style.height = isLargeScreenAndLevel1Menu(inner) ? `${offsetHeight - HEADER_HEIGHT}px` : `${offsetHeight}px`;
    });
  }
}

export default ViewportImpact;
