/* eslint-disable import/extensions */
import { DateTime } from 'luxon';
import { Dropdown } from '../../dropdown/js/module.js';
import { CardSelector } from '../../card-selector/js/module.js';
import CCCGoogleAnalytics4 from './CCCGoogleAnalytics4.js';

/*
    This is the CCC authenticated store
    - If making changes in the actual CCC store, might need to update the CCC layout store as well for screenshot testing purposes.

    For each specific authenticated page's store:
    1. store_authenticated_with_card.js
    2. store_authenticated_without_card.js
    3. store_authenticated_preregistration_disabled.js

    Each specific authenticated store needs to:
    - use authenticated_store as x-data and implement specific x-init
    - expose properties and functions used by the view to authenticated_store init
    - add any variables used by savedState to 'state'
    - implement these functions:
        . handleContinue
        . showErrorField (optional depends on whether SimpleAlert partial is included or not)
*/

const stepDirection = {
  next: 'next',
  prev: 'prev',
};

export const defaultValues = {
  selectedCardTypeSelection: '',
  isRegisterNewCard: true,
  selectedCustomerId: '',
  selectedCardNumber: '',
  selectedAccountDetail: null,
  useDifferentDeliveryAddressChecked: false,
  currentStep: 1,
};

export default (numberOfSteps, savedStateElementId, completeSummaryElementId, initStore, ga4MeasurementId) => ({
  hydratedFromSavedState: false,
  accountList: null,
  cardList: null,
  stepsCount: numberOfSteps,
  completeSummary: {},
  showFormSubmissionSpinner: false,
  showAlert: false,
  alertTitle: null,
  alertDescription: '',
  accessibilityFocus: false,
  addressFinderStore: null,
  state: {},

  // Init and Serialization/Hydrate
  init() {
    this.ga4 = new CCCGoogleAnalytics4(ga4MeasurementId);

    // Init default properties
    if (!this.hydratedFromSavedState) this.state = { ...this.state, ...defaultValues };

    // Init dropdowns
    const accountSelectorElement = document.getElementById('SelectedCustomerId');
    if (accountSelectorElement) this.accountSelector = this.initDropdown(accountSelectorElement);
    const cardSelectorElement = document.getElementById('SelectedCardNumber');
    if (cardSelectorElement) this.cardSelector = this.initDropdown(cardSelectorElement);

    // Loading saved state
    const element = document.getElementById(savedStateElementId);
    const value = element.value;
    if ((value || '').length > 0) {
      this.hydratedFromSavedState = true; // used in the child store to prevent savedState overwritten
      this.savedState = value;
    }

    // Loading complete summary
    const completeSummaryElem = document.getElementById(completeSummaryElementId);
    const completeSummaryValue = completeSummaryElem.value;
    if ((completeSummaryValue || '').length > 0) { this.completeSummary = JSON.parse(completeSummaryValue); }

    this.initChildStore();
    this.activateGa4PageView(); // we need this to send correct page view on load
  },

  ga4Event(eventName, eventParams = {}) {
    if (!this.ga4) return;
    this.ga4.event(eventName, eventParams, this.currentVirtualUrl());
  },

  computeGa4Url(domObject) {
    let url = domObject?.attributes?.virtualUrl?.value;
    if (!url) url = `/step/${this.state.currentStep}`;
    return url;
  },

  currentVirtualUrl() {
    const selectedShownTabs = document.querySelector('.ccc-container .content__tab:not([style*="display: none"])');
    return this.computeGa4Url(selectedShownTabs);
  },

  async activateGa4PageView() {
    // We will send the first page
    await this.awaitNextTick();
    this.ga4.pageView(window.document.title, window.location.href, this.currentVirtualUrl());

    // We will listen to any changes and send pageView as well
    const targetNode = document.querySelector('.ccc-container');
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver((mutations) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutations) {
        if (mutation.type === 'attributes'
                && mutation.attributeName === 'style'
                && mutation.target.classList.contains('content__tab')
                && mutation.target.style.display !== 'none'
        ) {
          this.ga4.pageView(window.document.title, window.location.href, this.computeGa4Url(mutation.target));
        }
      }
    });
    observer.observe(targetNode, config);
  },

  initChildStore() {
    initStore(this);
  },

  // For server calls, we need to reload/freeze previous state
  get savedState() {
    return JSON.stringify(this.state);
  },

  set savedState(savedString) {
    this.state = { ...this.state, ...JSON.parse(savedString) };
  },

  get accounts() {
    if (!this.accountList) {
      this.accountList = this.completeSummary.accounts.map(({ customer, accountType, cardCount }) => ({
        ...customer,
        type: accountType,
        cardCount,
        displayText: [customer.firstname, customer.lastname].join(' '),

      }));
    }
    return this.accountList;
  },

  get cards() {
    if (!this.cardList) {
      this.cardList = this.completeSummary.cards.map((card) => ({
        ...card,
        displayText: [card.cardnumber.replace(/\d{4}(?=.)/g, '$& '), '('.concat(card.cardname, '\'s card)')].join(' '),
      }));
    }

    return this.cardList;
  },

  setState(state) {
    this.state = {
      ...this.state,
      ...state,
    };
  },

  setAddressFinderStore(store) {
    this.addressFinderStore = store;
  },

  setFocus(refElement) {
    this.$nextTick(() => {
      this.$refs[refElement].focus();
    });
  },

  showSimpleAlert(title, description) {
    this.showAlert = true;
    this.alertTitle = title;
    this.alertDescription = description;
    this.setFocus('refSimpleAlert');
  },

  hideSimpleAlert() {
    this.showAlert = false;
    this.alertTitle = null;
    this.alertDescription = '';
  },

  showButton(buttonId) {
    if (buttonId === 'back' && this.state.currentStep > 1) {
      return true;
    } if (buttonId === 'continue' && this.state.currentStep < this.stepsCount) {
      return true;
    } if (buttonId === 'apply' && this.state.currentStep === this.stepsCount) {
      return true;
    }
    return false;
  },

  clearFormFields() {
    // eslint-disable-next-line no-param-reassign
    this.$nextTick(() => document.querySelectorAll('.at-text-input').forEach((field) => { field.value = ''; }));
  },

  // This is to solve issues after API error
  resetDropdownValues() {
    const differentDeliveryAddressCheckbox = document.getElementById('UseDifferentDeliveryAddress');
    if (differentDeliveryAddressCheckbox && differentDeliveryAddressCheckbox.value !== this.state.useDifferentDeliveryAddressChecked) differentDeliveryAddressCheckbox.value = this.state.useDifferentDeliveryAddressChecked;
  },

  async onStepChanged(direction) {
    let newStep;
    if (direction === stepDirection.next) {
      newStep = this.state.currentStep + 1;
      this.ga4Event('continue');
    }
    if (direction === stepDirection.prev) {
      newStep = this.state.currentStep - 1;
      this.ga4Event('go_back');
    }
    this.setState({ currentStep: newStep });
    await this.awaitNextTick();
    this.resetFormValidation();
    this.resetDropdownValues();
    this.hideSimpleAlert();
    this.accessibilityFocus = true;
    this.setFocus('refAccessibilityMainContent');
  },

  // Stepper controls
  async next() { await this.onStepChanged(stepDirection.next); },
  async prev() { await this.onStepChanged(stepDirection.prev); },
  showStep(stepNumber) { return this.state.currentStep === stepNumber; },

  // Step selection class
  stepSelectionClass(stepNumber) {
    if (stepNumber === this.state.currentStep) { return 'at-stepper__steps-item--selected'; }
    if (stepNumber < this.state.currentStep) { return 'at-stepper__steps-item--prev'; }
    return 'at-stepper__steps-item--next';
  },

  displayDate(dateStr) { return DateTime.fromISO(dateStr).toFormat('dd/MM/yyyy'); },

  displayAddress() {
    if (this.state.selectedAccountDetail.address) {
      const { address } = this.state.selectedAccountDetail;
      return `${address.addressline1}${address.unitOrFlatNumber ? `, ${address.unitOrFlatNumber}` : ''}, ${address.addressline2}, ${address.city} ${address.postcode}`;
    }
    return '';
  },

  showDifferentDeliveryAddressCheckbox() {
    return this.state.selectedAccountDetail && !this.state.selectedCardNumber && this.state.isRegisterNewCard;
  },

  showDifferentDeliveryAddressInput() {
    return this.state.selectedAccountDetail && this.state.useDifferentDeliveryAddressChecked;
  },

  clearAddressValue() {
    this.$nextTick(() => {
      $('.addressfinder-container').find('.input-validation-error').removeClass('input-validation-error');

      $('.addressfinder-container').find('[data-valmsg-replace]')
        .removeClass('field-validation-error')
        .addClass('field-validation-valid')
        .empty();

      const clearIcon = document.querySelector('.addressfinder-container .at-search__clear-icon');
      if (clearIcon) clearIcon.style.display = 'none';
    });
  },

  clearDifferentDeliveryAddress({ target }) {
    document.getElementById('UseDifferentDeliveryAddress').value = target.checked;

    if (target.checked) {
      this.addressFinderStore.address_loading = false;
      this.addressFinderStore.deliveryAddress = '';
      this.addressFinderStore.streetAddress = '';
      this.addressFinderStore.unitNumber = '';
      this.addressFinderStore.buildingName = '';
      this.addressFinderStore.suburb = '';
      this.addressFinderStore.city = '';
      this.addressFinderStore.postCode = '';

      this.clearAddressValue();
    }
  },

  // Shallow comparison for simple states only
  isStateChanged(snapshotStates) {
    let stateChanged = false;
    let newSnapshotStates;

    Object.keys(snapshotStates).forEach((snapshotKey) => {
      const newState = { ...snapshotStates[snapshotKey] };
      Object.keys(newState).forEach((key) => {
        if (newState[key] !== this.state[key]) {
          newState[key] = this.state[key];
          stateChanged = true;
        }
      });
      newSnapshotStates = { ...newState };
    });

    if (stateChanged) this.setState(newSnapshotStates);

    return stateChanged;
  },

  resetFormValidation() {
    const validationSummary = $('.validation-summary');

    // if API return more than one error code, only retain one validation summary after clientside validation
    const totalValidationSummary = validationSummary.length;
    if (totalValidationSummary > 1) {
      for (let i = 1; i < totalValidationSummary; i += 1) {
        validationSummary[i].remove();
      }
    }

    validationSummary.removeClass('at-border--warning', 'at-background-color--warning').addClass('at-border--error', 'at-background-color--error');
    validationSummary.removeClass('at-background-color--warning').addClass('at-background-color--error');
    validationSummary.attr({ role: 'alert', tabindex: 0 });
    validationSummary.find('.at-mt-0.at-mb-2.at-font__headline6').text('The form could not be submitted.');
    validationSummary.find('.at-mt-0.at-font__body2').text('Please change the errors below and try again:');
    validationSummary.find('.at-link.at-d-inline-flex').remove();
    validationSummary.removeClass('validation-summary-errors').addClass('validation-summary-valid');
    document.querySelector('.validation-summary .at-icon').classList.replace('at-icon--warning', 'at-icon--error');

    if (validationSummary.find('ul').length <= 0) {
      validationSummary.find('.at-mt-0.at-font__body2').append('<ul class="at-mt-2 at-mb-0 at-ps-5"></ul>');
    }

    const form = $('#communityConnectConcessionForm');

    form.validate().resetForm();

    form.find('[data-valmsg-replace]')
      .removeClass('field-validation-error')
      .addClass('field-validation-valid')
      .empty();

    form.removeData('validator')
      .removeData('unobtrusiveValidation');

    $.validator.setDefaults({ onkeyup: false });
    $.validator.unobtrusive.parse(form);
  },

  validateForm() {
    this.resetFormValidation();

    if ($('#communityConnectConcessionForm').valid()) {
      return true;
    }

    const validationSummary = $('.validation-summary');
    const validator = $('#communityConnectConcessionForm').validate();
    const list = validationSummary.find('ul').empty();

    $.each(validator.errorMap, (index, value) => {
      const displayName = $(`#${index.replace('.', '_')}`).attr('data-display-name');
      const errorMessage = value.replace(`The field ${displayName}`, '').replace(displayName, '');
      list.append(`<li class="at-mt-0 at-mb-1 at-font__body2"><a class="at-link at-link__text" href="#${index.replace('.', '_')}">${displayName}</a>${errorMessage}</li>`);
    });

    this.$nextTick(() => {
      $('.validation-summary').focus();
    });

    return false;
  },

  handleSubmit() {
    if (!this.validateForm()) {
      return false;
    }

    this.showFormSubmissionSpinner = true;
    this.setFocus('refLoadingSpinner');

    this.submitForm();
    return true;
  },

  async awaitNextTick() {
    await this.$nextTick();
  },

  setDispatch(target) {
    this.$dispatch(target);
  },

  initCardSelector(element) {
    return new CardSelector(element);
  },

  initDropdown(element) {
    return new Dropdown(element);
  },

  submitForm() {
    $('#communityConnectConcessionForm').submit();
  },
});
