import 'focus-visible';
import event from '../../event/js/module';
import collapse from '../../accordion/js/collapse';
import active from '../../accordion/js/active';
import clear from '../../clear/js/module';
import '../../layouts/js/module';
import '../../user-button/js/module';

export default (
  event,
  collapse,
  active,
  clear
);

// Focus on search box input when search icon clicked
const webSearchButton = document.getElementById('web-search-button');
if (webSearchButton) {
  webSearchButton.addEventListener('click', () => {
  // Set timeout for visibility to update visible
    setTimeout(() => {
      if (document.getElementById('web-search').classList.contains('active')) {
        document.getElementById('web-search-box__input').focus();
      }
    }, 200);
  });
}
