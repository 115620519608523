//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Menu always opens at level 1, up to level 3
// Each menu level has menu items
// A menu item can be an anchor tag or a parent (sub-menu) of a next level with its list of items (indicated with a chevron icon)
// Menu level 3 items will never be a parent (sub-menu) of a next level as it is the last menu level
// A back button only appears on level 2 and level 3 on MD and smaller screen sizes
// The close and back buttons will always be at the top (not scrollable) on mobile screen sizes
// There is a hidden span for screen reader to read if menu is opened/closed, obviously it won't work for all screen readers
//  - NVDA (majority accesibilty users) by default works straight out of the box
//  - Mac VO needs a slightly different way of reading it
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

import 'focus-visible';
import ViewportImpact from './viewportImpact';
import KeyboardNavigation, { focusNextTargetAnchor } from './keyboardNavigation';
import { enableFocusTrapping, disableFocusTrapping } from './focusTrapping';
import {
  SIDE_BAR_ANIMATION_DURATION,
  SCREENSIZE_SM,
  elementSideMenu,
  elementMenuInnerLevel,
  elementMenuToggleButton,
  elementMenuCloseButton,
  elementMenuBackButtons,
  elementAlphaBackground,
  elementGrowler,
} from './globals';

// if the page has a vertical bar, when menu opens it will hide the scroll bar and cause the content to shift
// so the idea is to keep the content width to be same as before the menu is opened to avoid the shifting effect
// this only happens on SM and above screen sizes, below those, menu will open full screen
const updateBodyAndHtmlStyle = () => {
  if (elementSideMenu.classList.contains('active') && window.innerHeight < document.body.clientHeight) {
    const bodyWidth = document.body.clientWidth;
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
    const bodyWidthDifference = bodyWidth - document.body.clientWidth;
    if (window.innerWidth >= SCREENSIZE_SM && bodyWidthDifference !== 0) {
      document.body.style.width = `${bodyWidth}px`;
      document.querySelector('html').style.backgroundColor = 'rgba(44, 56, 76, 0.5)';
    } else {
      document.body.style.width = '100%';
      document.querySelector('html').style.backgroundColor = null;
    }
  } else {
    document.body.style.width = null;
    document.body.style.position = null;
    document.body.style.overflow = document.querySelector('#AT_Journey_Planner') ? 'auto' : null;
    document.querySelector('html').style.backgroundColor = null;
  }
};

// find the parent of a selected element within a scope
const findParentInScope = (element, targetClass, limitParentElement) => {
  if (element.classList.contains(targetClass)) return element;

  const parent = element.parentElement;
  if (!!parent && limitParentElement.contains(parent)) {
    return findParentInScope(parent, targetClass, limitParentElement);
  }

  return null;
};

const unselectMenuItems = (element) => {
  element
    .querySelectorAll('.at-menu__item.selected')
    .forEach((selectedItem) => selectedItem.classList.remove('selected'));
};

const updateSelectedItem = (selectedListItem, currentLevel) => {
  let level = currentLevel;

  do {
    unselectMenuItems(elementMenuInnerLevel[level]);
    level += 1;
  } while (level <= 3);

  selectedListItem.classList.add('selected');
};

// to indicate which menu level is active
const updateMenuLevel = (level) => {
  // this will only be triggered for mobile and tablet device
  // to resolve focus problem for screen reader on those devices.
  if (window.innerWidth < SCREENSIZE_SM) {
    let levelNumber = 1;
    do {
      elementMenuInnerLevel[levelNumber].setAttribute('aria-hidden', levelNumber !== level);
      levelNumber += 1;
    } while (levelNumber <= 3);
    focusNextTargetAnchor();
  }

  if (level > 0) {
    elementSideMenu.classList.replace(`active-level-${elementSideMenu.dataset.level}`, `active-level-${level}`);
  }

  elementSideMenu.dataset.level = level;
};

// event handler for .at-menu__list (ul tag)
// checks if the selected menu item has a child list then populate and show the next menu level
// otherwise if it's an anchor tag go to the href link
const unorderedListHandler = (e) => {
  const selectedMenuInner = findParentInScope(e.target, 'at-menu', elementSideMenu);
  const selectedLevel = [1, 2, 3].find((level) => elementMenuInnerLevel[level] === selectedMenuInner);
  const selectedMenuList = elementMenuInnerLevel[selectedLevel].querySelector(':scope > .at-menu__list');
  const selectedItem = findParentInScope(e.target, 'at-menu__item', selectedMenuList);
  if (!selectedItem) return;

  updateSelectedItem(selectedItem, selectedLevel);

  const childList = selectedItem.querySelector('.at-menu__list');
  if (!childList) return;

  e.preventDefault();
  const nextLevel = Number(selectedLevel) + 1;
  const elementNextMenuInner = elementMenuInnerLevel[nextLevel];
  updateMenuLevel(nextLevel);

  // remove existing ul tag if any
  const nextLastChild = elementNextMenuInner.lastElementChild;
  const listAlreadyExists = nextLastChild.tagName.toLowerCase() === 'ul' && nextLastChild.classList.contains('at-menu__list');
  const existingList = listAlreadyExists ? nextLastChild : null;
  if (existingList) {
    existingList.removeEventListener('click', unorderedListHandler);
    existingList
      .querySelectorAll('.at-menu__list')
      .forEach((menuList) => menuList.removeEventListener('click', unorderedListHandler));
    existingList.remove();
  }

  // populate and show next list
  const nextMenuList = childList.cloneNode(true);
  elementNextMenuInner.appendChild(nextMenuList);
  if (nextLevel === 2) nextMenuList.addEventListener('click', unorderedListHandler);
  // level 1 list click event listeners are set when side menu opens on openSideMenu()
  // level 3 menu items have no child lists

  // update next level header
  const nextMenuHeader = elementNextMenuInner.querySelector('.at-menu__header');
  if (nextMenuHeader) nextMenuHeader.textContent = selectedItem.querySelector('.at-menu__label').textContent;
};

const backButtonHandler = () => {
  const existingList = elementMenuInnerLevel[Number(elementSideMenu.dataset.level)].querySelector('.at-menu__list');
  existingList.removeEventListener('click', unorderedListHandler);
  existingList.remove();
  const newLevel = Number(elementSideMenu.dataset.level) - 1;
  const selectedItem = elementMenuInnerLevel[newLevel].querySelector('.at-menu__item.selected');
  selectedItem.classList.remove('selected');
  updateMenuLevel(newLevel);

  selectedItem.firstElementChild.focus();
};

const openSideMenu = () => {
  if (elementSideMenu.classList.contains('active')) return;

  // this is to handle scenarios where the menu opens when growler partly visible or header partly visible due to scrolling
  window.scroll({ top: 0 });

  // timeout needed to fix iOS/iphone issues
  setTimeout(() => {
    // for screen reader
    const elementMenuStatus = elementSideMenu.querySelector('.at-menu__status');
    elementMenuStatus.textContent = 'Menu is opened';
    elementMenuStatus.setAttribute('tabindex', 0);

    updateMenuLevel(1);
    elementMenuToggleButton.setAttribute('aria-expanded', 'true');
    elementSideMenu.setAttribute('data-focus-trapping', 'itself');

    // this will only be triggered for mobile and tablet device
    // to resolve focus problem for screen reader on those devices.
    if (window.innerWidth < SCREENSIZE_SM) {
      enableFocusTrapping(document.body);
    }

    elementAlphaBackground.classList.add('active');
    elementSideMenu.classList.add('active', 'active-level-1');

    // add listeners
    elementSideMenu.addEventListener('keydown', KeyboardNavigation.keydownHandler);
    elementMenuBackButtons.forEach((button) => button.addEventListener('click', backButtonHandler));
    elementMenuInnerLevel[1].querySelector('.at-menu__list').addEventListener('click', unorderedListHandler);
    window.addEventListener('resize', updateBodyAndHtmlStyle);

    updateBodyAndHtmlStyle();
  }, 10);
};

const closeSideMenu = () => {
  if (!elementSideMenu.classList.contains('active')) return;

  // for screen reader
  const elementMenuStatus = elementSideMenu.querySelector('.at-menu__status');
  elementMenuStatus.textContent = 'Menu is closed';
  elementMenuStatus.setAttribute('tabindex', 0);
  elementMenuStatus.focus();

  elementSideMenu.classList.remove(`active-level-${elementSideMenu.dataset.level}`, 'active');
  elementAlphaBackground.classList.remove('active');
  elementMenuToggleButton.setAttribute('aria-expanded', 'false');
  elementSideMenu.removeAttribute('data-focus-trapping');

  // this will only be triggered for mobile and tablet device
  // to resolve focus problem for screen reader on those devices.
  if (window.innerWidth < SCREENSIZE_SM) {
    disableFocusTrapping(document.body);
  }

  unselectMenuItems(elementSideMenu);
  updateMenuLevel(0);

  // remove listeners
  elementSideMenu.removeEventListener('keydown', KeyboardNavigation.keydownHandler);
  elementMenuBackButtons.forEach((button) => button.removeEventListener('click', backButtonHandler));
  elementSideMenu
    .querySelectorAll('.at-menu__list')
    .forEach((menuList) => menuList.removeEventListener('click', unorderedListHandler));
  window.removeEventListener('resize', updateBodyAndHtmlStyle);

  updateBodyAndHtmlStyle();

  setTimeout(() => {
    elementMenuToggleButton.focus();
  }, SIDE_BAR_ANIMATION_DURATION);
};

class Menu {
  static initialise() {
    // close side menu if loading from a cache and side menu is open
    // cache navigation is being used in safari and mobile devices which will return last html state and structure
    // for example if we select a menu item that goes to a new page then we go back to the previous page, the menu is still open
    window.onpageshow = (event) => {
      if (event.persisted && elementSideMenu.classList.contains('active')) closeSideMenu();
    };

    elementMenuCloseButton.addEventListener('click', closeSideMenu);

    const elementMenuStatus = elementSideMenu.querySelector('.at-menu__status');
    elementMenuStatus.addEventListener('focusout', () => elementMenuStatus.removeAttribute('tabindex')); // for screen reader

    // event listeners that will impact menu inner heights
    window.addEventListener('load', ViewportImpact.updateMenuInnersHeight);
    window.addEventListener('resize', ViewportImpact.updateMenuInnersHeight);
    window.addEventListener('scroll', ViewportImpact.updateMenuInnersHeight);
    window.addEventListener('orientationchange', closeSideMenu);
    if (elementGrowler) ViewportImpact.addGrowlerCloseButtonEventListener();

    // event listeners to close menu after clicking outside of it (on MD and above screen sizes)
    elementSideMenu.addEventListener('click', ({ target }) => {
      if (target.classList.contains('at-menu__button-close')) return;
      const parent = findParentInScope(target, 'at-menu__inner', elementSideMenu);
      if (!parent) closeSideMenu();
    });

    elementAlphaBackground.addEventListener('click', closeSideMenu); // this can only be triggered on XL and above screen sizes

    // toggle menu listener (click)
    elementMenuToggleButton.addEventListener('click', () => {
      openSideMenu();
      setTimeout(() => elementMenuStatus.focus(), SIDE_BAR_ANIMATION_DURATION);
    });

    // toggle menu listener (keypress)
    elementMenuToggleButton.addEventListener('keypress', (e) => {
      e.preventDefault();
      if (e.keyCode === 13 || e.key === 'Enter' || e.keyCode === 32 || e.key === ' ') {
        openSideMenu();
        setTimeout(() => elementMenuCloseButton.focus(), SIDE_BAR_ANIMATION_DURATION);
      }
    });
  }
}

if (elementSideMenu && elementMenuCloseButton) {
  Menu.initialise();
}
