import 'focus-visible';
import Event from '../../event/js/module';
import KeyboardEvent from '../../event/js/keyboard';

const initializeAccordion = (element = 'accordion') => {
  Event.eventHandler(element, 'click', (buttonElement, panelElement, event) => {
    event.preventDefault();

    const isExpanded = buttonElement.getAttribute('aria-expanded') === 'true';

    buttonElement.setAttribute('aria-expanded', !isExpanded);

    if (isExpanded) {
      panelElement.classList.add('at-d-none');
      buttonElement.classList.remove('at-accordion-component--expanded');
    } else {
      panelElement.classList.remove('at-d-none');
      buttonElement.classList.add('at-accordion-component--expanded');
    }
  });

  Event.eventHandler(element, 'keydown', (buttonElement, panelElement, event) => {
    switch (true) {
      case KeyboardEvent.CheckKey(event, KeyboardEvent.Key.UP):
        event.preventDefault();
        event.stopPropagation();

        buttonElement.parentElement.previousElementSibling?.previousElementSibling?.firstElementChild.focus();
        break;
      case KeyboardEvent.CheckKey(event, KeyboardEvent.Key.DOWN):
        event.preventDefault();
        event.stopPropagation();

        panelElement?.nextElementSibling?.firstElementChild.focus();
        break;
      default:
        break;
    }
  });
};

export default initializeAccordion;
