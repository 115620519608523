import 'focus-visible';
import {
  sidebarMobileMenu,
  sidebarMenuToggleButton,
  sidebarMenuAlphaBackground,
  sidebarMobileMenuLevel1,
  sidebarMobileMenuLevel2List,
  webSearchButton,
  documentHeader,
  atBody,
} from './global';
import {
  elementMenuToggleButton,
} from '../../menu/js/globals';

const ACTIVE_CLASS = 'active';
const NO_SCROLL_CLASS = 'no-scroll';
const SIDEBAR_MENU_HEADER_CLASS = 'at-sidebar-menu__header';

const closeMobileSidebarMenu = () => {
  atBody?.classList.remove(NO_SCROLL_CLASS);
  sidebarMenuToggleButton.setAttribute('aria-expanded', 'false');
  sidebarMenuToggleButton.classList.remove(ACTIVE_CLASS);
  sidebarMobileMenu.classList.remove(ACTIVE_CLASS);
  sidebarMenuAlphaBackground.classList.remove(ACTIVE_CLASS);

  sidebarMobileMenuLevel1.classList.remove(ACTIVE_CLASS);
  sidebarMobileMenuLevel1.style.top = '-100%';

  sidebarMobileMenuLevel2List.forEach((element) => element.classList.remove(ACTIVE_CLASS));
};

const openMobileSidebarMenu = () => {
  window.scroll({ top: 0 });
  documentHeader?.classList.add(SIDEBAR_MENU_HEADER_CLASS);
  atBody?.classList.add(NO_SCROLL_CLASS);
  sidebarMenuToggleButton.setAttribute('aria-expanded', 'true');
  sidebarMenuToggleButton.classList.add(ACTIVE_CLASS);
  sidebarMobileMenu.classList.add(ACTIVE_CLASS);
  sidebarMenuAlphaBackground.classList.add(ACTIVE_CLASS);

  const sidebarMenuToggleRect = sidebarMenuToggleButton.getBoundingClientRect();
  sidebarMobileMenuLevel1.classList.add(ACTIVE_CLASS);
  sidebarMobileMenuLevel1.style.top = `${sidebarMenuToggleRect.bottom}px`;
};

const toggleMobileSidebarMenu = () => {
  if (sidebarMobileMenu.classList.contains(ACTIVE_CLASS)) {
    closeMobileSidebarMenu();
    return;
  }

  // If the search box is open, then first close it before opening mobile sidebar menu
  if (webSearchButton && document.getElementById('web-search').classList?.contains(ACTIVE_CLASS)) {
    webSearchButton.click();
    setTimeout(() => {
      openMobileSidebarMenu();
    }, 400);
    return;
  }

  openMobileSidebarMenu();
};

const toggleMobileSidebarMenuLevel2 = (className) => {
  const element = document.querySelector(`.${className}`);

  if (!element) {
    return;
  }

  if (element.classList.contains(ACTIVE_CLASS)) {
    element.classList.remove(ACTIVE_CLASS);
    return;
  }

  window.scroll({ top: 0 });
  element.classList.add(ACTIVE_CLASS);
  const sidebarMenuToggleRect = sidebarMenuToggleButton.getBoundingClientRect();
  element.style.top = `${sidebarMenuToggleRect.bottom}px`;
};

class MobileSidebarMenu {
  static initialise() {
    window.addEventListener('orientationchange', closeMobileSidebarMenu);

    if (!sidebarMenuToggleButton) {
      return;
    }

    // toggle menu listener (click)
    sidebarMenuToggleButton.addEventListener('click', () => {
      toggleMobileSidebarMenu();
    });

    webSearchButton?.addEventListener('click', () => {
      if (sidebarMenuToggleButton.classList.contains(ACTIVE_CLASS)) {
        closeMobileSidebarMenu();
      }
    });

    elementMenuToggleButton?.addEventListener('click', () => {
      if (sidebarMenuToggleButton.classList.contains(ACTIVE_CLASS)) {
        closeMobileSidebarMenu();
      }
    });
  }
}

if (sidebarMobileMenu) {
  MobileSidebarMenu.initialise();
}

export {
  // eslint-disable-next-line import/prefer-default-export
  toggleMobileSidebarMenuLevel2,
};
