class KeyboardEvent {
  // NOTE:
  // KeyboardEvent 'keyCode' is depcreated, please read https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
  // We use 'key' to first determine what character corresponds with the key event, and keep 'keyCode' as fallback for old browsers
  // We don't use 'code' because it represents a physical key on the keyboard, it returns a value that isn't altered by keyboard layout or the state of the modifier keys
  // For example:
  // A keyboard that has a numpad, Pressing the numpad-2 key
  // Using 'key', it will return either "ArrowDown" or "2" depends on the NumLock status
  // Using 'code', it will always return "Numpad2" regardless of the NumLock status
  static Key = {
    LEFT: ['Left', 'ArrowLeft', 37],
    UP: ['Up', 'ArrowUp', 38],
    RIGHT: ['Right', 'ArrowRight', 39],
    DOWN: ['Down', 'ArrowDown', 40],
    HOME: ['Home', 36],
    END: ['End', 35],
    SPACE: [' ', 'Spacebar', 32],
    BACKSPACE: ['Backspace', 8],
    ESC: ['Esc', 'Escape', 27],
    ENTER: ['Enter', 13],
    TAB: ['Tab', 9],
  };

  /**
   * Check key of keyboard event is valid
   * @param {KeyboardEvent} keyboardEvent - Keyboard event
   * @param {Array} targetKeys - List of allowed key or keyCode.
   */
  static CheckKey = (keyboardEvent, targetKeys) => {
    const key = keyboardEvent.key || keyboardEvent.keyCode; // Check if 'key' is implemented, otherwise fallback to 'keyCode'
    return targetKeys.includes(key);
  };
}
export default KeyboardEvent;