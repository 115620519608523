//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Global consts
//--------------------------------------------------------------------------------------------------------------------------------------------------------------
const prefixClass = '.at-sidebar-menu';
export const sidebarMobileMenu = document.querySelector(`${prefixClass}__mobile`);
export const sidebarMenuToggleButton = document.querySelector(`${prefixClass}__button-toggle`);
export const sidebarMenuAlphaBackground = document.querySelector(`${prefixClass}__alpha-bg`);
export const sidebarMobileMenuLevel1 = document.querySelector(`${prefixClass}__mobile-level1`);
export const sidebarMobileMenuLevel2List = document.querySelectorAll(`${prefixClass}__mobile-level2`);
export const webSearchButton = document.getElementById('web-search-button');
export const atBody = document.querySelector('.at-body');
export const documentHeader = document.querySelector('header');
