function createSvgElement() {
  const div = document.createElement('div');
  div.style.display = 'none';
  div.innerHTML = this.responseText;
  document.body.insertBefore(div, document.body.childNodes[0]);
}

const initializeSvg = (svgSrc) => {
  const oReq = new XMLHttpRequest();
  oReq.addEventListener('load', createSvgElement);
  oReq.open('GET', svgSrc);
  oReq.send();
};

export default initializeSvg;