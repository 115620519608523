import KeyboardEvent from '../../event/js/keyboard';
import 'focus-visible';

class Tabs {
  /**
   * @param { HTMLElement } tabsElementSelector
   */
  constructor(tabsElementSelector) {
    this.navigators = tabsElementSelector.querySelectorAll('[data-element="tabs-navigator"]'); // prev or next
    this.tabsHeader = tabsElementSelector.querySelector('.at-tabs__header');
    this.numberOfTabs = this.tabsHeader.children.length;
    this.hasHorizontalScroll = this.checkHasHorizontalScroll();

    if (this.numberOfTabs > 0) this.selectTargetTab(1);

    function preventEventEffect(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.tabsHeader.addEventListener('keyup', (e) => {
      switch (true) {
        case KeyboardEvent.CheckKey(e, KeyboardEvent.Key.LEFT):
          preventEventEffect(e);
          this.selectTargetTab(this.getPreviousTabIndex(), true);
          break;
        case KeyboardEvent.CheckKey(e, KeyboardEvent.Key.RIGHT):
          preventEventEffect(e);
          this.selectTargetTab(this.getNextTabIndex(), true);
          break;
        case KeyboardEvent.CheckKey(e, KeyboardEvent.Key.HOME):
          preventEventEffect(e);
          this.selectTargetTab(1, true);
          break;
        case KeyboardEvent.CheckKey(e, KeyboardEvent.Key.END):
          preventEventEffect(e);
          this.selectTargetTab(this.numberOfTabs, true);
          break;
        default:
          break;
      }
    });

    this.tabsHeader.addEventListener('click', (e) => {
      preventEventEffect(e);

      const targetTabIndex = e.target.getAttribute('data-tabs-index');
      if (targetTabIndex) this.selectTargetTab(targetTabIndex);
    });

    this.navigators.forEach((item) => {
      const direction = item.getAttribute('data-tabs-direction');
      if (!direction) return;

      item.addEventListener('click', (e) => {
        preventEventEffect(e);

        const targetTabIndex = direction === 'previous' ? this.getPreviousTabIndex() : this.getNextTabIndex();
        if (targetTabIndex) this.selectTargetTab(targetTabIndex);
      });
    });

    // Change visibility of navigator when screen size change
    window.addEventListener('resize', () => {
      const hasHorizontalScroll = this.checkHasHorizontalScroll();

      if (this.hasHorizontalScroll !== hasHorizontalScroll) {
        this.hasHorizontalScroll = hasHorizontalScroll;
        this.toggleNavigatorsVisibility();
      }
    });
  }

  checkHasHorizontalScroll() {
    return this.tabsHeader.scrollWidth > this.tabsHeader.clientWidth;
  }

  getSelectedTabIndex() {
    return Number(this.tabsHeader.getAttribute('data-tabs-selected-index'));
  }

  getPreviousTabIndex() {
    return this.getSelectedTabIndex() > 1 ? this.getSelectedTabIndex() - 1 : this.numberOfTabs;
  }

  getNextTabIndex() {
    return this.getSelectedTabIndex() < this.numberOfTabs ? this.getSelectedTabIndex() + 1 : 1;
  }

  selectTargetTab(targetTabIndex, focusable = false) {
    // Deselect current tab
    const currentTab = this.tabsHeader.querySelector(`[data-tabs-index='${this.getSelectedTabIndex()}']`);
    currentTab.setAttribute('aria-selected', false);
    const currentContentTab = document.getElementById(currentTab.getAttribute('aria-controls'));
    currentTab.classList.remove('at-tabs__item--selected');
    currentTab.setAttribute('tabindex', -1);
    currentContentTab?.classList.add('at-d-none');

    // Select target tab
    const targetTab = this.tabsHeader.querySelector(`[data-tabs-index='${targetTabIndex}']`);
    targetTab.setAttribute('aria-selected', true);
    const targetContentTab = document.getElementById(targetTab.getAttribute('aria-controls'));
    targetTab.removeAttribute('tabindex');
    targetTab.classList.add('at-tabs__item--selected');
    targetContentTab?.classList.remove('at-d-none');

    // Focus and/or scroll view to target tab
    if (focusable) targetTab.focus();
    const leftPosition = targetTab.offsetLeft;
    this.tabsHeader.scrollLeft = leftPosition - 44;

    // Update header
    this.tabsHeader.setAttribute('aria-labelledby', targetTab.id);
    this.tabsHeader.setAttribute('data-tabs-selected-index', targetTabIndex);
    this.selectedTabIndex = Number(targetTabIndex);

    // Update visibility of navigators when selected tab change
    if (this.hasHorizontalScroll) {
      this.toggleNavigatorsVisibility();
    }
  }

  toggleNavigatorsVisibility() {
    this.navigators.forEach((navigator) => {
      const direction = navigator.getAttribute('data-tabs-direction');

      if (!this.hasHorizontalScroll) {
        navigator.classList.add('at-d-none');
        navigator.classList.remove('at-d-flex');
        return;
      }

      const canGoPrevious = direction === 'previous' && this.selectedTabIndex !== 1;
      const canGoNext = direction === 'next' && this.selectedTabIndex !== this.numberOfTabs;
      const shouldDisplay = canGoPrevious || canGoNext;

      if (shouldDisplay) {
        navigator.classList.add('at-d-flex');
        navigator.classList.remove('at-d-none');
      } else {
        navigator.classList.add('at-d-none');
        navigator.classList.remove('at-d-flex');
      }
    });
  }
}

const initializeTabs = (selector = '[data-element="tablist"]') => {
  document.querySelectorAll(selector).forEach((element) => new Tabs(element));
};

export { Tabs, initializeTabs };
