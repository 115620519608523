/**
 * Create a chuck for x-data in alpinejs
 * @param { string } name - An unique name for searchbox
 * @param { Array<{id: string, title: string}> } items - The list of item needs to be searched.
 * @param { number } pageSize - Number of items to display on each page, default value is set to 10.
 */
const createSearchBox = (name, items, pageSize = 10) => ({
  name,
  items,
  pageNumber: 1,
  searchKeyword: '',
  resultId: `${name}-result`,
  get trimmedSearchKeyword() {
    return this.searchKeyword.trim();
  },
  get isSearching() {
    return this.trimmedSearchKeyword !== '';
  },
  get numberOfResults() {
    return this.items.filter((item) => item.enable).length;
  },
  get hasMoreResults() {
    return this.numberOfResults - (this.pageNumber * pageSize) > 0;
  },
  searchProcess() {
    const searchData = items.map((item) => {
      if (!this.trimmedSearchKeyword) return { ...item, enable: true };

      // Normalize whitespace to sync search resource with UI (remove extra spaces)
      const cleanTitle = item.title.replace(/\s{2,}/g, ' ');
      const enable = cleanTitle.toLowerCase().includes(this.trimmedSearchKeyword.toLowerCase());
      return { ...item, enable };
    });

    this.items = searchData.filter((item) => item.enable).map((item, index) => {
      const result = { ...item, visible: index < this.pageNumber * pageSize };
      return result;
    });
  },
  isVisible(id) {
    return !!this.items.find((item) => item.id === id)?.visible;
  },
  numberOfItemsShown() {
    return this.hasMoreResults ? this.pageNumber * pageSize : this.items.filter((item) => item.enable).length;
  },
  refreshSearch(pageNumber = 1) {
    this.pageNumber = pageNumber;
    this.searchProcess();
  },
  async onClearSearchKeyword(elementSelectorToFocusOn) {
    this.searchKeyword = '';
    await this.$nextTick();
    this.refreshSearch();
    await this.$nextTick();
    const targetElement = document.querySelector(elementSelectorToFocusOn);
    if (targetElement) targetElement.focus();
  },
  onEnter() {
    if (!this.isSearching) return;
    const result = document.getElementById(this.resultId);

    if (result) {
      // Set the result to be focusable and focus on it
      result.setAttribute('tabindex', 0);
      result.focus();
    }
  },
  /**
   * @param { Event } e
   */
  onResultFocusOut(e) {
    // Remove the tabindex to make the result unfocusable after losing focus until hitting enter again
    e.target.removeAttribute('tabindex');
  },
  async onShowMore() {
    const itemNum = pageSize * this.pageNumber;
    this.refreshSearch(this.pageNumber + 1);
    await this.$nextTick();
    const targetId = this.items.filter((item) => item.visible)[itemNum]?.id;
    const targetElement = document.querySelector(`#${name}-${targetId} .at-card`);
    if (targetElement) targetElement.focus();
  },
});

export default createSearchBox;