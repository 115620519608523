//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// Keyboard navigation
//--------------------------------------------------------------------------------------------------------------------------------------------------------------

import KeyboardEvent from '../../event/js/keyboard';
import {
  SIDE_BAR_ANIMATION_DURATION,
  SCREENSIZE_LG,
  elementSideMenu,
  elementMenuInnerLevel,
  elementMenuToggleButton,
  elementMenuCloseButton,
} from './globals';

const focusMenuToggleButton = () => setTimeout(() => elementMenuToggleButton.focus(), SIDE_BAR_ANIMATION_DURATION);
const getCurrentMenuLevel = () => Number(elementSideMenu.dataset.level);
const getCurrentElementMenuInner = () => elementMenuInnerLevel[getCurrentMenuLevel()];
export const focusNextTargetAnchor = () => setTimeout(() => getCurrentElementMenuInner()?.querySelector('.at-menu__anchor').focus(), SIDE_BAR_ANIMATION_DURATION);

const getNavigationLinks = () => {
  const navigationElements = Array.from(getCurrentElementMenuInner().querySelectorAll(`
    :scope > .at-menu__homebar > .at-menu__anchor-home,
    :scope > div > .at-menu__button-back,
    :scope > .at-menu__list > .at-menu__item > .at-menu__anchor
  `));

  const menuLevel1OnLargeScreen = window.innerWidth >= SCREENSIZE_LG && getCurrentMenuLevel() > 1;
  // LG and above screen sizes only have 1 close button (at level 1) instead of every levels (see Figma)
  return menuLevel1OnLargeScreen ? navigationElements : [document.querySelector('.at-menu .at-menu__button-close'), ...navigationElements];
};

const getTargetItem = (target, direction = 'next') => {
  const elementList = getCurrentElementMenuInner().querySelector('.at-menu__list');
  const navigationLinks = getNavigationLinks();
  const isNextTarget = direction === 'next'; // next or previous target
  let targetIndex = navigationLinks.indexOf(target);
  let targetItem;

  do {
    targetIndex = isNextTarget ? targetIndex + 1 : targetIndex - 1;

    // loop through in current menu level
    if (targetIndex > navigationLinks.length - 1) targetIndex = 0;
    if (targetIndex < 0) targetIndex = navigationLinks.length - 1;

    targetItem = navigationLinks[targetIndex];
  } while (window.innerWidth >= SCREENSIZE_LG && targetItem.classList.contains('at-menu__button-back')); // skip invisible back button on LG and above screen sizes

  // keep target item visible on list
  const { scrollHeight: scrollbarHeight, clientHeight: scrollbarViewHeight, scrollTop: scrollBarPosition } = elementList;
  const { clientHeight: targetItemHeight, parentNode: targetItemParent } = targetItem;

  // if scrollbar
  if (scrollbarViewHeight < scrollbarHeight) {
    const itemList = Array.from(elementList.children);
    const itemPositionList = itemList.map((child, index) => ({ index, position: (index + 1) * child.clientHeight }));
    const itemIndex = itemList.indexOf(targetItemParent);
    const itemPosition = itemPositionList.find((item) => item.index === itemIndex)?.position;
    // get item position relative to the scroll bar

    if (isNextTarget && itemPosition > scrollbarViewHeight + scrollBarPosition) {
      elementList.scrollTop = scrollBarPosition + scrollbarViewHeight + itemPosition;
    }

    if (!isNextTarget && itemPosition - targetItemHeight < scrollBarPosition) {
      elementList.scrollTop = scrollBarPosition - scrollbarViewHeight - itemPosition;
    }
  }

  return targetItem;
};

class KeyboardNavigation {
  static keydownHandler = (userEvent) => {
    const { target } = userEvent;
    userEvent.preventDefault();

    switch (true) {
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB):
        if (userEvent.shiftKey) {
          getTargetItem(target, 'prev').focus();
        } else {
          getTargetItem(target, 'next').focus();
        }
        break;
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.DOWN):
        getTargetItem(target, 'next').focus();
        break;
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.UP):
        getTargetItem(target, 'prev').focus();
        break;
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.LEFT):
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.BACKSPACE):
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.ESC):
        if (getCurrentMenuLevel() > 1) { // Level 1 doesn't have a back button.
          getCurrentElementMenuInner()
            .querySelector(':scope > div > .at-menu__button-back')
            .click();
        } else {
          elementMenuCloseButton.click();
          focusMenuToggleButton();
        }
        break;
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.RIGHT):
        if (target.nextElementSibling?.classList.contains('at-menu__list')) {
          target.click();
          focusNextTargetAnchor();
        }
        break;
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.SPACE):
      case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.ENTER):
        target.click();
        if (target.classList.contains('at-menu__button-close')) {
          focusMenuToggleButton();
        }
        if (target.nextElementSibling?.classList.contains('at-menu__list')) {
          focusNextTargetAnchor();
        }
        break;
      default:
        break;
        // Quit when this doesn't handle the key event.
    }
  };
}

export default KeyboardNavigation;
