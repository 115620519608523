/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import authenticatedStore, { defaultValues } from './store_authenticated.js';

// !!WARNING!!
// Please do not use the 'destructuring assigment' for functions and assigning values, it will not work as expected
// The 'destructuring assignment' can only be used as a look-up only
//
// NOTE: parent refers to store_authenticated properties
//       (similar to inheritance) so please be aware of overwriting variables and / or functions
//       If making changes in the actual CCC store, might need to update the CCC layout store as well for screenshot testing purposes.
//
const initAuthenticatedWithCard = (parent) => {
  parent.accessibilityErrorFocus = false; // accessibility focus toggler after error alert is clicked/pressed

  parent.cardTypeValue = {
    new: 'new',
    existing: 'existing',
  };
  parent.cardTypeIcon = {
    new: 'badge-athop-delivery',
    existing: 'badge-athop-registered',
  };
  parent.cardTypeText = {
    title: {
      new: 'New AT HOP card.',
      existing: 'Existing AT HOP card.',
    },
    description: {
      new: 'Order a new, free HOP card loaded with the Community Connect concession.',
      existing: 'Choose an existing card to apply the Community Connect concession. Once applied, any previous concession will be removed from your AT HOP card.',
    },
  };

  // Init card type selector
  const cardtypeSelectorElement = document.getElementById('SelectedCardTypeSelection');
  if (cardtypeSelectorElement) parent.cardTypeSelector = parent.initCardSelector(cardtypeSelectorElement);

  // Init snapshot states
  if (!parent.hydratedFromSavedState) { // prevent savedState being overwritten
    const step1State = {
      selectedCardTypeSelection: defaultValues.selectedCardTypeSelection,
    };

    const step2State = {
      selectedCardTypeSelection: defaultValues.selectedCardTypeSelection,
      isRegisterNewCard: defaultValues.isRegisterNewCard,
      selectedCardNumber: defaultValues.selectedCardNumber,
      selectedCustomerId: defaultValues.selectedCustomerId,
    };

    parent.setState({ step1State, step2State });
  }

  // Focus on error field after alert clicked or pressed
  parent.showErrorField = () => {
    const { currentStep, selectedCardTypeSelection } = parent.state;

    if (currentStep === 1) {
      parent.accessibilityErrorFocus = true;
      parent.setFocus('refCardTypeSelection');
    }

    if (currentStep === 2) {
      if (selectedCardTypeSelection === parent.cardTypeValue.new) {
        parent.setFocus('refAccountSelection');
      }
      if (selectedCardTypeSelection === parent.cardTypeValue.existing) {
        parent.setFocus('refCardSelection');
      }
    }
  };

  parent.clearStep2DataIfStateChanged = ({ step1State }) => {
    if (parent.isStateChanged({ step1State })) {
      parent.setState({
        selectedAccountDetail: defaultValues.selectedAccountDetail, // IMPORTANT! parent linke have to be first to trigger re-rendering to avoid race condition of LastName not appearing
        isRegisterNewCard: defaultValues.isRegisterNewCard,
        selectedCustomerId: defaultValues.selectedCustomerId,
        selectedCardNumber: defaultValues.selectedCardNumber,
      });

      parent.clearFormFields();
    }
  };

  parent.clearStep3DataIfStateChanged = async ({ step2State, isRegisterNewCard, selectedCardTypeSelection }) => {
    if (parent.isStateChanged({ step2State })) {
      parent.setState({ useDifferentDeliveryAddressChecked: false });

      await parent.awaitNextTick();
      document.getElementById('MSDClientId').value = '';
      document.getElementById('IsTCsRead').checked = false;
      document.getElementById('IsConsentProvided').checked = false;

      if (selectedCardTypeSelection === parent.cardTypeValue.new) {
        parent.setDispatch('collapsemanualaddressfinder');
        parent.setDispatch('clearaddressvalue');
        parent.clearAddressValue();

        if (!isRegisterNewCard) {
          parent.setState({
            selectedAccountDetail: defaultValues.selectedAccountDetail, // IMPORTANT! parent linke have to be first to trigger re-rendering to avoid race condition of LastName not appearing
            selectedCustomerId: defaultValues.selectedCustomerId,
          });

          parent.clearFormFields();
        }
      }
    }
  };

  parent.isValidToStep2 = ({ selectedCardTypeSelection }) => {
    if (selectedCardTypeSelection) return true;

    parent.showSimpleAlert(null, 'Select an option before continue to application');
    return false;
  };

  parent.isValidToStep3NewCard = ({
    state: { isRegisterNewCard, selectedCustomerId }, accounts,
  }) => {
    if (!isRegisterNewCard) return true;

    if (selectedCustomerId) {
      parent.setState({ selectedAccountDetail: accounts.find((account) => account.customerid === selectedCustomerId) });
      const numberOfCardsForSelectedAccount = parent.state.selectedAccountDetail.cardCount;
      if (numberOfCardsForSelectedAccount < 9) {
        return true;
      }
      parent.showSimpleAlert('Your selected account reached the limit for the number of AT HOP Cards', 'Please select another account or add a new linked account');
      return false;
    }

    parent.showSimpleAlert(null, 'Please choose an account to continue with your application');
    return false;
  };

  parent.isValidToStep3ExistingCard = ({ state: { selectedCardNumber }, accounts, cards }) => {
    if (selectedCardNumber) {
      const selectedCard = cards.find((card) => card.cardnumber === selectedCardNumber);

      parent.setState({ selectedAccountDetail: accounts.find((account) => account.customerid === selectedCard.customerid) });
      return true;
    }

    parent.showSimpleAlert(null, 'Please choose a card to continue with your application');
    return false;
  };

  parent.handleContinue = async () => {
    const { currentStep, selectedCardTypeSelection } = parent.state;

    if (currentStep === 1 && parent.isValidToStep2(parent.state)) {
      await parent.clearStep2DataIfStateChanged(parent.state);
      parent.ga4Event('select_card_type', { card_type_selected: parent.state.selectedCardTypeSelection });
      await parent.next();
    }
    if (currentStep === 2 && selectedCardTypeSelection === parent.cardTypeValue.new && parent.isValidToStep3NewCard(parent)) {
      await parent.clearStep3DataIfStateChanged(parent.state);
      parent.ga4Event('select_preregistration', { preregistration: parent.state.isRegisterNewCard });
      await parent.next();
    }
    if (currentStep === 2 && selectedCardTypeSelection === parent.cardTypeValue.existing && parent.isValidToStep3ExistingCard(parent)) {
      await parent.clearStep3DataIfStateChanged(parent.state);
      await parent.next();
    }
  };
};

export default (numberOfSteps, savedStateElementId, completeSummaryElementId, ga4MeasurementId) => authenticatedStore(numberOfSteps, savedStateElementId, completeSummaryElementId, initAuthenticatedWithCard, ga4MeasurementId);
