/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
// Somehow, it does not work when not done this way
function gtag() {
  window.dataLayer.push(arguments);
}

export default class GoogleAnalytics4 {
  constructor(ga4Account, appName) {
    if (!ga4Account) throw new Error('Mandatory ga4Account is not defined');
    if (!appName) throw new Error('Mandatory appName is not defined');
    this.appName = appName;
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    this.ga4Account = ga4Account;

    // If using debug console in ga4: gtag('config', ga4Account, { debug_mode: true });
    gtag('config', ga4Account);
  }

  event(eventName, eventProperties, virtual_url) {
    const prop = {
      ...eventProperties,
      app_name: this.appName,
      event_source: 'app_code',
      send_to: this.ga4Account,
    };
    if (virtual_url) prop.virtual_url = virtual_url;

    gtag('event', eventName, prop);
  }

  pageView(page_title, page_location, page_path) {
    const props = {
      page_title,
      page_location,
      page_path,
      virtual_url: page_path,
      event_source: 'app_code',
      send_to: this.ga4Account,
    };

    gtag('event', 'page_view', props);
  }

  computeGa4Url(domObject, defaultUrl) {
    let url = domObject?.attributes?.virtualUrl?.value;
    if (!url) url = defaultUrl;
    return url;
  }

  currentVirtualUrl(containerSelector, defaultUrl) {
    const selectedShownTabs = document.querySelector(containerSelector);

    return this.computeGa4Url(selectedShownTabs, defaultUrl);
  }
}