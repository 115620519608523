import 'focus-visible/dist/focus-visible';
import KeyboardEvent from '../../event/js/keyboard';

/**
 * Dropdown Listbox with Chips
 * This component was developed by following the guide from W3C (https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 * Consists of a dropdown menu with options that, when selected, will generate a chip to be displayed.
*/
const isValidItem = (item) => item && item.getAttribute('role') === 'option' && !item.classList.contains('empty');

const isExpandedWithCallback = (buttonElement, callback) => {
  if (buttonElement.getAttribute('aria-expanded') === 'true') return true;

  callback();
  return false;
};

const hideChip = (itemId) => {
  const element = document.getElementById(`chip-${itemId}`);
  if (element !== null) {
    element.classList.add('at-chip--hidden');
  }
};

const showChip = (itemId) => {
  const element = document.getElementById(`chip-${itemId}`);
  if (element !== null) {
    element.classList.remove('at-chip--hidden');
    element.setAttribute('aria-checked', 'true');
  }
};

const getHighLightedItem = (dataset) => {
  const {
    listElement,
    keyboardNavigation,
  } = dataset;

  if (!keyboardNavigation) {
    return null;
  }

  const list = Array.from(listElement.children).filter((child) => child.classList.contains('highlighted'));
  if (!list || list.length === 0) {
    return null;
  }

  return list[0];
};

const highlightItem = (dataset, item) => {
  const {
    listElement,
    keyboardNavigation,
  } = dataset;

  if (!isValidItem(item) || !keyboardNavigation) {
    return;
  }

  Array.from(listElement.children)
    .filter((child) => child.classList.contains('highlighted'))
    .forEach((child) => child.classList.remove('highlighted'));

  item.classList.add('highlighted');
  listElement.setAttribute('aria-activedescendant', item.id);

  // Scroll the list element if selected item is not in the list view
  if (listElement.scrollHeight > listElement.clientHeight) {
    const scrollBottom = listElement.clientHeight + listElement.scrollTop;
    const selectedItemBottom = item.offsetTop + item.offsetHeight;
    let scrollTopValue = listElement.scrollTop;

    if (selectedItemBottom > scrollBottom) {
      scrollTopValue = selectedItemBottom - listElement.clientHeight;
    } else if (item.offsetTop < listElement.scrollTop) {
      scrollTopValue = item.offsetTop;
    }

    // eslint-disable-next-line no-param-reassign
    listElement.scrollTop = scrollTopValue;
  }
};

const getSelectionLabelText = (count) => {
  if (count === 0) {
    return 'No option selected';
  }
  return count > 1 ? `${count} options selected` : `${count} option selected`;
};

const unSelectChip = (inputElementId, unselectedItemId) => {
  const inputElement = document.getElementById(inputElementId);
  const buttonElement = document.getElementById(`${inputElementId}-button`);
  const textElement = document.getElementById(`${inputElementId}-text`);
  const selectionLabelElement = document.getElementById(`${inputElementId}-selection-label`);
  const unselectedItem = document.getElementById(unselectedItemId);

  const valueList = (inputElement.getAttribute('value') || '')
    .split(',')
    .filter((id) => id !== unselectedItemId);

  inputElement.setAttribute('value', valueList.join(','));
  const selectionLabelText = getSelectionLabelText(valueList.length);
  selectionLabelElement.innerHTML = selectionLabelText;
  buttonElement.setAttribute('aria-label', `${textElement.innerHTML}, ${selectionLabelText}`);

  if (unselectedItem.classList) {
    unselectedItem.classList.remove('selected');
    unselectedItem.setAttribute('aria-selected', 'false');
  }

  hideChip(unselectedItemId);
  buttonElement.focus();
};

const selectItem = (dataset, selectedItem) => {
  const {
    buttonElement,
    listElement,
    inputElement,
    textElement,
    selectionLabelElement,
    keyboardNavigation,
  } = dataset;

  if (!isValidItem(selectedItem)) {
    return;
  }

  if (keyboardNavigation) {
    buttonElement.blur();
    selectedItem.classList.add('aria-selected');
  }

  listElement.setAttribute('aria-activedescendant', selectedItem.id);

  selectedItem.setAttribute('aria-selected', 'true');
  selectedItem.classList.add('selected');

  const valueList = (inputElement.getAttribute('value') || '')
    .split(',')
    .filter((item) => item !== '');
  valueList.push(selectedItem.id);
  inputElement.setAttribute('value', Array.from(new Set(valueList)).join(','));

  const selectionLabelText = getSelectionLabelText(new Set(valueList).size);
  selectionLabelElement.innerHTML = selectionLabelText;
  buttonElement.setAttribute('aria-label', `${textElement.innerHTML}, ${selectionLabelText}`);

  showChip(selectedItem.id);
};

const collapseList = (dataset) => {
  const {
    buttonElement,
    listElement,
    iconElement,
    keyboardNavigation,
  } = dataset;

  if (isExpandedWithCallback(buttonElement, () => {})) {
    buttonElement.setAttribute('aria-expanded', 'false');
    listElement.classList.replace('at-dropdown__list--expanded', 'at-dropdown__list--collapsed');
    iconElement.classList.replace('chevron--top', 'chevron--bottom');

    if (keyboardNavigation) buttonElement.classList.add('focus-visible');

    Array.from(listElement.children)
      .filter((child) => child.classList.contains('highlighted'))
      .forEach((child) => child.classList.remove('highlighted'));

    listElement.blur();
    buttonElement.focus();
  }
};

const expandList = (dataset) => {
  const {
    buttonElement,
    listElement,
    iconElement,
    keyboardNavigation,
  } = dataset;

  buttonElement.setAttribute('aria-expanded', 'true');
  listElement.classList.replace('at-dropdown__list--collapsed', 'at-dropdown__list--expanded');
  iconElement.classList.replace('chevron--bottom', 'chevron--top');

  if (!keyboardNavigation) listElement.querySelectorAll('[role="option"]').forEach((element) => element.classList.remove('aria-selected'));

  listElement.focus();

  const firstItem = listElement.querySelector('.at-dropdown__item, [role="option"]');
  if (firstItem) {
    highlightItem(dataset, firstItem);
  }
};

// Expand or collapse
const toggleList = (dataset) => {
  const { buttonElement, userEvent } = dataset;

  userEvent.preventDefault();

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    collapseList(dataset);
  }
};

const moveToFirstItem = (dataset) => {
  const { buttonElement, listElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    const firstItem = listElement.querySelector('[role="option"]');
    highlightItem(dataset, firstItem);
  }
};

const moveUpItem = (dataset) => {
  const { buttonElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    const currentItem = getHighLightedItem(dataset);
    if (currentItem === null) {
      moveToFirstItem(dataset);
      return;
    }
    const prevItem = currentItem.previousElementSibling;
    highlightItem(dataset, prevItem);
  }
};

const moveDownItem = (dataset) => {
  const { buttonElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    const currentItem = getHighLightedItem(dataset);
    if (currentItem === null) {
      moveToFirstItem(dataset);
      return;
    }
    const nextItem = currentItem.nextElementSibling;
    highlightItem(dataset, nextItem);
  }
};

const moveToLastItem = (dataset) => {
  const { buttonElement, listElement } = dataset;

  if (isExpandedWithCallback(buttonElement, () => expandList(dataset))) {
    const lastItem = listElement.lastElementChild;
    highlightItem(dataset, lastItem);
  }
};

const handleTabKeyCode = (dataset) => {
  const { buttonElement } = dataset;

  buttonElement.classList.add('focus-visible');

  if (isExpandedWithCallback(buttonElement, () => buttonElement.classList.add('focus-visible'))) {
    collapseList(dataset);
  }
};

const handleItemClicked = (dataset) => {
  const { userEvent } = dataset;
  const selectedItem = userEvent.target;

  userEvent.preventDefault();

  if (isValidItem(selectedItem)) {
    selectItem(dataset, selectedItem);
    collapseList(dataset);
  }
};

const handleKeyPressed = (dataset) => {
  const { userEvent } = dataset;

  switch (true) {
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.UP):
      userEvent.preventDefault();
      moveUpItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.DOWN):
      userEvent.preventDefault();
      moveDownItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.HOME):
      userEvent.preventDefault();
      moveToFirstItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.END):
      userEvent.preventDefault();
      moveToLastItem(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.BACKSPACE):
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.ESC):
      userEvent.preventDefault();
      collapseList(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.ENTER):
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.SPACE):
      userEvent.preventDefault();
      selectItem(dataset, getHighLightedItem(dataset));
      toggleList(dataset);
      break;
    case KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB):
      handleTabKeyCode(dataset);
      break;
    default:
      break;
  }
};

class ChipsDropdown {
  constructor(inputElement) {
    this.inputElement = inputElement;
    this.buttonElement = document.querySelector(`[data-dropdown-button-for="${this.inputElement.id}"]`);
    this.listElement = document.querySelector(`[data-dropdown-list-for="${this.inputElement.id}"]`);
    this.iconElement = document.querySelector(`[data-dropdown-icon-for="${this.inputElement.id}"]`);
    this.textElement = document.getElementById(`${this.inputElement.id}-text`);
    this.selectionLabelElement = document.getElementById(`${this.inputElement.id}-selection-label`);

    this.dataset = {
      buttonElement: this.buttonElement,
      listElement: this.listElement,
      inputElement: this.inputElement,
      iconElement: this.iconElement,
      textElement: this.textElement,
      selectionLabelElement: this.selectionLabelElement,
      keyboardNavigation: true,
    };

    const observer = new MutationObserver((mutationRecords) => {
      mutationRecords.forEach((record) => {
        if (record.attributeName === 'value') {
          this.buttonElement.dispatchEvent(new Event('change'));
          this.inputElement.dispatchEvent(new Event('input'));
        }
      });
    });
    observer.observe(this.inputElement, { attributes: true });

    // Set initial value if any
    const dropdownValue = this.inputElement.getAttribute('value');
    if (dropdownValue && dropdownValue !== '') {
      this.value = dropdownValue;
    }

    // Specific event listener to handle TAB (on first focus)
    this.buttonElement.addEventListener('keyup', (userEvent) => {
      if (KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB)) handleKeyPressed({ ...this.dataset, userEvent });
    });

    this.buttonElement.addEventListener('keydown', (userEvent) => {
      if (!KeyboardEvent.CheckKey(userEvent, KeyboardEvent.Key.TAB)) handleKeyPressed({ ...this.dataset, userEvent });
    });

    this.buttonElement.addEventListener('mousedown', (userEvent) => toggleList({ ...this.dataset, userEvent, keyboardNavigation: false }));

    this.listElement.addEventListener('click', (userEvent) => handleItemClicked({ ...this.dataset, userEvent, keyboardNavigation: false }));
    this.listElement.addEventListener('keydown', (userEvent) => handleKeyPressed({ ...this.dataset, userEvent }));
    this.listElement.addEventListener('blur', () => collapseList({ ...this.dataset, keyboardNavigation: false }));

    const selectedItemIds = inputElement.value.split(',');
    if (selectedItemIds && selectedItemIds.length > 0) {
      selectedItemIds.forEach((itemId) => {
        const item = document.getElementById(itemId);
        selectItem(this.dataset, item);
      });
    }
  }

  set value(value) {
    this.inputElement.setAttribute('value', value);
  }

  get value() {
    return this.inputElement.getAttribute('value');
  }

  addButtonEventListener(event, listener) {
    this.buttonElement.addEventListener(event, listener);
  }

  addListEventListener(event, listener) {
    this.listElement.addEventListener(event, listener);
  }

  expand(keyboardNavigation = false) {
    this.dataset.keyboardNavigation = keyboardNavigation;
    expandList(this.dataset);
  }

  collapse(keyboardNavigation = false) {
    this.dataset.keyboardNavigation = keyboardNavigation;
    collapseList(this.dataset);
  }
}

const initializeChipsDropdown = () => {
  document.querySelectorAll('[data-element="chips-dropdown"]').forEach((dropdownElement) => new ChipsDropdown(dropdownElement));
};

export {
  ChipsDropdown,
  initializeChipsDropdown,
  unSelectChip,
};
