import 'focus-visible/dist/focus-visible';

const keyboardKey = {
  SPACE: [' ', 32],
  ENTER: ['Enter', 13],
};

const checkKeyboardKey = (keyboardEvent, targetKey) => {
  const key = keyboardEvent.key || keyboardEvent.keyCode; // Check if 'key' is implemented, otherwise fallback to 'keyCode'

  return targetKey.includes(key);
};

const hopCardSelectorClass = 'hop-card-selector';

const isCardAlreadySelected = (hopCard) => hopCard && hopCard.classList.contains(`${hopCardSelectorClass}__item--selected`);

const addSelectedClass = (hopCard) => {
  hopCard.classList.add(`${hopCardSelectorClass}__item--selected`);
  hopCard.setAttribute('aria-pressed', true);
};

const removeSelectedClass = (hopCard) => {
  hopCard.classList.remove(`${hopCardSelectorClass}__item--selected`);
  hopCard.setAttribute('aria-pressed', false);
};

const setCheckedAttribute = (toggleElement) => {
  toggleElement.setAttribute('checked', '');
  // eslint-disable-next-line no-param-reassign
  toggleElement.checked = true;
};

const removeCheckedAttribute = (toggleElement) => {
  toggleElement.removeAttribute('checked');
  // eslint-disable-next-line no-param-reassign
  toggleElement.checked = false;
};

const handleSelection = (hopCard, userEvent) => {
  userEvent.preventDefault();
  const toggleElement = hopCard.querySelector('input');

  if (isCardAlreadySelected(hopCard)) {
    removeSelectedClass(hopCard);
    removeCheckedAttribute(toggleElement);
  } else {
    addSelectedClass(hopCard);
    setCheckedAttribute(toggleElement);
  }
};

const formatCardNumber = (cardNumber) => {
  if (typeof cardNumber === 'string') {
    return cardNumber.match(/.{1,4}/g).join(' ');
  }
  return '';
};

const initializeHopCardSelector = () => {
  document.querySelectorAll(`.${hopCardSelectorClass}__item`)
    .forEach((hopCard) => {
      const cardNumberElement = hopCard.querySelector(`.${hopCardSelectorClass}__card-number`);
      cardNumberElement.innerHTML = formatCardNumber(cardNumberElement.innerHTML);

      hopCard.addEventListener('click', (userEvent) => handleSelection(hopCard, userEvent));

      hopCard.addEventListener('keydown', (userEvent) => {
        if (checkKeyboardKey(userEvent, keyboardKey.ENTER) || checkKeyboardKey(userEvent, keyboardKey.SPACE)) {
          handleSelection(hopCard, userEvent);
        }
      });
    });
};

export {
  initializeHopCardSelector,
  formatCardNumber,
};
