import 'focus-visible';
import popout from '../../popout/js/module';

popout('auth-popout', {
  onTrigger(instance) {
    instance.reference.classList.add('at-user-button--active');
  },
  onHide(instance) {
    instance.reference.classList.remove('at-user-button--active');
  },
});