import 'focus-visible';

// Use by template/html with data-active/data-collapse attributes
// eslint-disable-next-line
import { collapse } from '../../accordion/js/module';

const addGrowlerButtonCloseEvent = (growlerButtonEl, growlerLinkEl) => {
  growlerButtonEl.addEventListener(
    'click',
    () => {
      growlerButtonEl.setAttribute('tabindex', '-1');
      localStorage.setItem('at-growler__last-dismissed', growlerButtonEl.dataset.growlerDate);

      if (growlerLinkEl) {
        growlerLinkEl.setAttribute('tabindex', '-1');
      }
    },
  );
};

const displayGrowler = (growlerEl, growlerButtonEl) => {
  const lastDismissedGrowlerDateTicks = localStorage.getItem('at-growler__last-dismissed');
  const currentGrowlerDateTicks = growlerButtonEl.dataset.growlerDate;

  if (!lastDismissedGrowlerDateTicks || currentGrowlerDateTicks > lastDismissedGrowlerDateTicks) {
    // eslint-disable-next-line no-param-reassign
    growlerEl.style.display = 'block';
  }
};

const initializeGrowler = () => {
  const atGrowler = document.querySelector('#at-growler');
  if (atGrowler) {
    const atGrowlerButton = atGrowler.querySelector('.at-growler__button');
    const atGrowlerLink = atGrowler.querySelector('.at-growler__link');

    addGrowlerButtonCloseEvent(atGrowlerButton, atGrowlerLink);
    displayGrowler(atGrowler, atGrowlerButton);
  }
};

export default initializeGrowler;