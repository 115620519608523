import { Dropdown } from '../../dropdown/js/module';
import GoogleAnalytics4 from '../../analytics/js/GoogleAnalytics';

/*
    Corporate EHS Concession store
    - If making changes in the actual concession core store, might need to update the concession layout store as well for screenshot testing purposes.
*/

export default (ga4MeasurementId) => ({
  state: {
    selectedCardNumber: null,
    currentStep: 1,
    vcSuccessfullyValidated: false,
    termsAndConditionsAccepted: false,
    vcQRCode: '',
    vcAuthUrl: '',
    vcPresentationState: '',
  },
  stepsCount: 2,
  completeSummary: {},
  buttonStepMap: {
    continue: 1,
    apply: 2,
    back: 2,
  },
  cardList: null,
  showAlert: false,
  alertTitle: null,
  alertDescription: '',
  formId: 'corporateEHSConcessionForm',
  showFormSubmissionSpinner: false,
  vcIntervalId: 0,
  vcSuccessStatuses: ['presentation_verified'],
  vcErrorStatuses: ['error', 'no_valid_credential'],
  vcEndStatuses: ['presentation_verified', 'error', 'no_valid_credential'],
  vcIsLoading: true,
  vcStatusIdealAttempts: 0,
  vcAttemptsCounter: 1,
  vcStatus: '',
  ga4: null,
  init() {
    if (!ga4MeasurementId) throw new Error('GA4 Measurement Id is mandatory');
    // Init Google Analytics
    this.ga4 = new GoogleAnalytics4(ga4MeasurementId, 'employer_hop_subsidy');
    // Init dropdowns
    const accountSelectorElement = document.getElementById('SelectedCustomerId');
    if (accountSelectorElement) this.accountSelector = this.initDropdown(accountSelectorElement);
    const cardSelectorElement = document.getElementById('SelectedCardNumber');
    if (cardSelectorElement) this.cardSelector = this.initDropdown(cardSelectorElement);
    // Loading complete summary
    const completeSummaryElem = document.getElementById('SimplifiedCompleteSummaryJson');
    const completeSummaryValue = completeSummaryElem.value;
    if ((completeSummaryValue || '').length > 0) { this.completeSummary = JSON.parse(completeSummaryValue); }

    this.activateGa4EHSPageView(); // needed to send correct page view on load
  },
  delay(delayInms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(() => resolve(), delayInms));
  },
  // Verifiable Credentials logic
  async vcHandleCredentials(timeoutMilliseconds, initPresentationUrl, initPresentationRequestBody, presentationStatusUrl) {
    await this.vcInitiatePresentationRequest(initPresentationUrl, initPresentationRequestBody);
    if (this.state.vcQRCode && this.state.vcPresentationState) {
      this.vcCheckCredentialState(timeoutMilliseconds, presentationStatusUrl);
    }
  },
  async vcInitiatePresentationRequest(url, body = {}) {
    try {
      const response = await this.httpRequest(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const responseBody = await response.json();

      if (response.status === 400) {
        // TODO: show error with API
        this.showSimpleAlert('Sorry, Verifiable Credential service is currently unavailable to complete your application.', 'Try again later.');
        return;
      }

      if (response.status > 400) {
        throw new Error(`HTTP status code: ${response.status}`);
      }

      if (responseBody) {
        this.state.vcQRCode = responseBody?.qrCode;
        this.state.vcAuthUrl = responseBody?.url;
        this.state.vcPresentationState = responseBody?.state;
      }
    } catch (error) {
      await this.delay(this.timeoutMilliseconds);
      // if an error happens we keep trying to do requests
      await this.vcInitiatePresentationRequest(url, body);
    }
  },
  vcCheckCredentialState(timeoutMilliseconds, url) {
    this.calculateIdealStatusAttempts(timeoutMilliseconds);
    this.vcIntervalId = setInterval(async () => {
      if (!this.state.vcPresentationState) {
        return;
      }

      await this.handleVcRequest(`${url}${this.state.vcPresentationState}`);
      this.vcAttemptsCounter += 1;

      // We track the number of attemps for the cache TTL. not to make extra requests when not receiving entra callback.
      if (this.vcAttemptsCounter === this.vcStatusIdealAttempts) {
        clearInterval(this.vcIntervalId);
        this.vcIsLoading = false;
        this.state.vcSuccessfullyValidated = false;
      }

      // if the request keep awaiting for user interaction, we do nothing
      if (this.vcIsLoading) {
        return;
      }

      // we show the tick or cross when the vc requests stops happening (when an end status happens).
      if (this.vcSuccessStatuses.includes(this.vcStatus)) {
        document.getElementById('VcValidated').value = true;
        this.state.vcSuccessfullyValidated = true;
      }
      if (this.vcErrorStatuses.includes(this.vcStatus)) {
        this.state.vcSuccessfullyValidated = false;
      }
    }, timeoutMilliseconds);
  },
  async handleVcRequest(url) {
    try {
      const response = await this.httpRequest(url);

      if (response.status >= 400) {
        throw new Error(`HTTP status code: ${response.status}`);
      }

      const responseBody = await response.json();

      if (responseBody.status) {
        this.vcStatus = responseBody.status;
        this.vcIsLoading = false;
      }

      // if the request has an "end" status (verified or error) we don't need to do more ajax requests to the api.
      if (this.vcEndStatuses.includes(this.vcStatus)) {
        this.vcIsLoading = false;
        clearInterval(this.vcIntervalId);
      }
    } catch (error) {
      // if an error happens we keep trying to do requests
      this.vcStatus = 'error';
      this.state.vcSuccessfullyValidated = false;
    }
  },
  calculateIdealStatusAttempts(timeoutMilliseconds) {
    // this is the maximum time that the cache exists in credibil side
    const tenMinutesInMilliseconds = 600000; // 10 * 60 * 1000
    this.vcStatusIdealAttempts = tenMinutesInMilliseconds / timeoutMilliseconds;
  },
  httpRequest(url, options = {}) {
    return fetch(url, options);
  },
  // Form controls / actions
  initDropdown(element) {
    return new Dropdown(element);
  },
  setFocus(refElement) {
    this.$nextTick(() => {
      this.$refs[refElement].focus();
    });
  },
  showSimpleAlert(title, description) {
    this.showAlert = true;
    this.alertTitle = title;
    this.alertDescription = description;
    this.setFocus('refSimpleAlert');
  },
  hideSimpleAlert() {
    this.showAlert = false;
    this.alertTitle = null;
    this.alertDescription = '';
  },
  stepClass() {
    return 'at-stepper__steps-item--next';
  },
  // Step selection class
  stepSelectionClass(stepNumber) {
    if (stepNumber === this.state.currentStep) { return 'at-stepper__steps-item--selected'; }
    if (stepNumber < this.state.currentStep) { return 'at-stepper__steps-item--prev'; }
    return 'at-stepper__steps-item--next';
  },
  showButton(buttonId) {
    return this.buttonStepMap[buttonId] === this.state.currentStep;
  },
  resetFormValidation() {
    const validationSummary = $('.validation-summary');

    // if API return more than one error code, only retain one validation summary after clientside validation
    const totalValidationSummary = validationSummary.length;
    if (totalValidationSummary > 1) {
      for (let i = 1; i < totalValidationSummary; i += 1) {
        validationSummary[i].remove();
      }
    }

    validationSummary.removeClass('at-border--warning', 'at-background-color--warning').addClass('at-border--error', 'at-background-color--error');
    validationSummary.removeClass('at-background-color--warning').addClass('at-background-color--error');
    validationSummary.attr({ role: 'alert', tabindex: 0 });
    validationSummary.find('.at-mt-0.at-mb-2.at-font__headline6').text('The form could not be submitted.');
    validationSummary.find('.at-mt-0.at-font__body2').text('Please change the errors below and try again:');
    validationSummary.find('.at-link.at-d-inline-flex').remove();
    validationSummary.removeClass('validation-summary-errors').addClass('validation-summary-valid');
    document.querySelector('.validation-summary .at-icon').classList.replace('at-icon--warning', 'at-icon--error');

    if (validationSummary.find('ul').length <= 0) {
      validationSummary.find('.at-mt-0.at-font__body2').append('<ul class="at-mt-2 at-mb-0 at-ps-5"></ul>');
    }

    const form = $(`#${this.formId}`);

    form.validate().resetForm();

    form.find('[data-valmsg-replace]')
      .removeClass('field-validation-error')
      .addClass('field-validation-valid')
      .empty();

    form.removeData('validator')
      .removeData('unobtrusiveValidation');

    $.validator.setDefaults({ onkeyup: false });
    $.validator.unobtrusive.parse(form);
  },
  validateForm() {
    this.resetFormValidation();

    if ($(`#${this.formId}`).valid()) {
      return true;
    }

    const validationSummary = $('.validation-summary');
    const validator = $(`#${this.formId}`).validate();
    const list = validationSummary.find('ul').empty();

    $.each(validator.errorMap, (index, value) => {
      const displayName = $(`#${index.replace('.', '_')}`).attr('data-display-name');
      const errorMessage = value.replace(`The field ${displayName}`, '').replace(displayName, '');
      list.append(`<li class="at-mt-0 at-mb-1 at-font__body2"><a class="at-link at-link__text" href="#${index.replace('.', '_')}">${displayName}</a>${errorMessage}</li>`);
    });

    this.$nextTick(() => {
      $('.validation-summary').focus();
    });

    return false;
  },
  // stepper controls
  showStep(stepNumber) { return this.state.currentStep === stepNumber; },
  async handleContinue() {
    if (!this.state.selectedCardNumber) {
      this.showSimpleAlert(null, 'AT hop card must be selected in order to continue.');
      return;
    }

    if (!this.state.termsAndConditionsAccepted) {
      this.showSimpleAlert(null, 'You must accept terms and conditions to continue');
      return;
    }

    const newStep = this.state.currentStep + 1;
    this.ga4Event('continue');
    this.setState({ currentStep: newStep });
    await this.awaitNextTick();
    this.resetFormValidation();
    this.hideSimpleAlert();
  },
  async prev() {
    const newStep = this.state.currentStep - 1;
    this.ga4Event('go_back');
    this.setState({ currentStep: newStep });
    await this.awaitNextTick();
    this.resetFormValidation();
    this.hideSimpleAlert();
  },
  handleSubmit() {
    if (!this.validateForm()) {
      return false;
    }

    this.showFormSubmissionSpinner = true;
    this.setFocus('refLoadingSpinner');

    this.submitForm();
    return true;
  },
  presentMobileCredential() {
    window.open(this.state.vcAuthUrl, '_blank').focus();
  },
  submitForm() {
    this.ga4Event('submit');
    $(`#${this.formId}`).submit();
  },
  get cards() {
    if (!this.cardList) {
      this.cardList = this.completeSummary.cards.map((card) => {
        const formattedCardNumber = card.cardnumber.replace(/\d{4}(?=.)/g, '$& ');
        const formattedCardName = card.cardname ? `(${card.cardname}'s card)` : '';
        const formattedDisplayText = [formattedCardNumber];

        if (formattedCardName) {
          formattedDisplayText.push(formattedCardName);
        }

        return {
          ...card,
          displayText: formattedDisplayText.join(' '),
        };
      });
    }

    return this.cardList;
  },
  // STATE
  // For server calls, we need to reload/freeze previous state
  get savedState() {
    return JSON.stringify(this.state);
  },
  set savedState(savedString) {
    this.state = { ...this.state, ...JSON.parse(savedString) };
  },
  setState(state) {
    this.state = {
      ...this.state,
      ...state,
    };
  },
  // Google analytics
  ga4Event(eventName, eventParams = {}) {
    if (!this.ga4) return;
    this.ga4.event(eventName, eventParams, this.ga4.currentVirtualUrl(`#${this.formId} .content__tab:not([style*="display: none"])`, `/step/${this.state.currentStep}`));
  },
  async awaitNextTick() {
    await this.$nextTick();
  },
  async activateGa4EHSPageView() {
    if (!this.ga4) return;
    // We will send the first page
    await this.awaitNextTick();
    this.ga4.pageView(window.document.title, window.location.href, this.ga4.currentVirtualUrl(`#${this.formId} .content__tab:not([style*="display: none"])`, `/step/${this.state.currentStep}`));

    // We will listen to any changes and send pageView as well
    const targetNode = document.querySelector('.at-concession-container');
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver((mutations) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutations) {
        if (mutation.type === 'attributes'
                && mutation.attributeName === 'style'
                && mutation.target.classList.contains('content__tab')
                && mutation.target.style.display !== 'none'
        ) {
          this.ga4.pageView(window.document.title, window.location.href, this.ga4.computeGa4Url(`/step/${mutation.target}`));
        }
      }
    });
    observer.observe(targetNode, config);
  },
});
