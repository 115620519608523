import 'smooth-scroll/dist/smooth-scroll.polyfills';

const smoothScroll = {
  speed: 300,
  speedAsDuration: true,
};

window.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line
  new SmoothScroll('[data-scroll="smooth"]', smoothScroll);
});

const isFieldContainsValue = (field) => field !== undefined && field !== null && field.match(/^ *$/) === null;

export {
  smoothScroll,
  isFieldContainsValue,
};