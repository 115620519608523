import event from '../../event/js/module';

// The idea of using data-xxx attr is to make this code more flexible and reusable so that we can, ideally,
// use it for other components without repeating code.

export default event.eventHandler(
  'collapse',
  'click',
  (toggler) => {
    const { target, breakpoint } = toggler.dataset;
    const shouldToggleBeEnabled = (breakpoint && window.innerWidth < parseFloat(breakpoint)) || !breakpoint;
    if (shouldToggleBeEnabled) { // choose whether you want collapsing enable for all screen sizes (optional)
      document.querySelectorAll(target)
        .forEach((targetElement) => targetElement.classList.toggle('collapse--active'));
    }
  },
);
