/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import authenticatedStore, { defaultValues } from './store_authenticated.js';

// !!WARNING!!
// Please do not use the 'destructuring assigment' for functions and assigning values, it will not work as expected
// The 'destructuring assignment' can only be used as a look-up only
//
// NOTE: parent refers to store_authenticated properties
//       (similar to inheritance) so please be aware of overwriting variables and / or functions
//       If making changes in the actual CCC store, might need to update the CCC layout store as well for screenshot testing purposes.
//
const initAuthenticatedPreregistrationDisabled = (parent) => {
  if (!parent.hydratedFromSavedState) { // prevent savedState being overwritten
    const step1State = {
      selectedCardNumber: defaultValues.selectedCardNumber,
      selectedAccountDetail: defaultValues.selectedAccountDetail,
      fillThisForm: false, // This is us to capture when the "Fill out this form" link is clicked instead of continue button
    };

    parent.setState({ step1State });
  }

  parent.showErrorField = () => {
    parent.setFocus('refCardSelection');
  };

  parent.handleContinue = async (fromFillThisForm = false) => {
    const { step1State } = parent.state;

    if (!fromFillThisForm && !parent.state.selectedCardNumber) {
      parent.showSimpleAlert(null, 'Please choose a card to continue with your application');
    } else if (fromFillThisForm) {
      // Fill my form have not much validation
      parent.fillThisForm = true;
      if (parent.isStateChanged({ step1State })) {
        parent.setState({
          selectedAccountDetail: defaultValues.selectedAccountDetail, // IMPORTANT! parent linke have to be first to trigger re-rendering to avoid race condition of LastName not appearing
          selectedCardNumber: defaultValues.selectedCardNumber,
        });

        parent.clearFormFields();
      }
      parent.ga4.event('select_card_type', { card_type_selected: 'new' }, parent.currentVirtualUrl());
      await parent.next();
    } else {
      parent.fillThisForm = false;
      const { state: { selectedCardNumber }, cards, accounts } = parent;
      const selectedCard = cards.find((card) => card.cardnumber === selectedCardNumber);
      parent.setState({ selectedAccountDetail: accounts.find((account) => account.customerid === selectedCard.customerid) });

      if (parent.isStateChanged({ step1State })) {
        parent.clearFormFields();
      }
      await parent.next();
    }
  };
};

export default (numberOfSteps, savedStateElementId, completeSummaryElementId, ga4MeasurementId) => authenticatedStore(numberOfSteps, savedStateElementId, completeSummaryElementId, initAuthenticatedPreregistrationDisabled, ga4MeasurementId);
