import event from '../../event/js/module';

/**
 * Clear input value
 * @param dataElement - element which contains dataset and represents the trigger.
 * @param targetElement - element which be affected once the event is triggered.
*/

// Listens for input changes and hides or shows the clear button
const listenToInputTargetChange = document.querySelectorAll("[data-element='clear']")
  .forEach((clearButton) => {
    const input = document.querySelector(clearButton.dataset.clearTarget);
    input.addEventListener('input', (e) => (e.target.value === '' ? clearButton.classList.add('d-none') : clearButton.classList.remove('d-none')));
  });

// Handles clear button on click
const clearInputTargetValueOnClick = event.eventHandler(
  'clear',
  'click',
  (dataElement, targetElement) => {
    // eslint-disable-next-line no-param-reassign
    targetElement.value = '';
    dataElement.classList.add('d-none');
  },
);

export default {
  listenToInputTargetChange,
  clearInputTargetValueOnClick,
};
